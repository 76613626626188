import style from "./Footer.module.scss";
import typ from "../../../Styles/typography.module.scss";

import {ReactComponent as VisaFlag} from "../../../Images/Vectors/visa-brand-flag.svg";

const Footer = () => {
  return (
    <section className={style.footer}>
        <div>
            <div>
                <VisaFlag />
            </div>
            <div className={style.text}>
                <p className={typ.smallPrint}>
                © 2022 Visa. All Rights Reserved. Visa Confidential. Case studies, comparisons, statistics, research and recommendations are provided “AS IS” and intended for informational purposes only and should not be relied upon for operational, marketing, legal, technical, tax, financial or other advice. Visa Inc. neither makes any warranty or representation as to the completeness or accuracy of the information within this document, nor assumes any liability or responsibility that may result from reliance on such information. The Information contained herein is not intended as investment or legal advice, and readers are encouraged to seek the advice of a competent professional where such advice is required.
                </p>
            </div>
        </div>
    </section>
  )
}

export default Footer