import { useEffect } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import axiosConfig from "Services/axiosConfig";

import "./App.scss";
import ScrollToTop from "./Components/UI/ScrollToTop/ScrollToTop";

import Footer from "./Components/Layout/Footer/Footer";

import PrivateRoute from "Components/Auth/PrivateRoute";
import { Menu } from "Components/UI/Menu";
import { VisaLogoColours } from "Components/UI/VisaLogo";
import LoginOverlay from "./Components/UI/LoginOverlay/LoginOverlay";

import Chapter1 from "./Components/Pages/Chapter1/Chapter1";
import Chapter2 from "./Components/Pages/Chapter2/Chapter2";
import Chapter3 from "./Components/Pages/Chapter3/Chapter3";
import Chapter4 from "./Components/Pages/Chapter4/Chapter4";
import Chapter5 from "./Components/Pages/Chapter5/Chapter5";
import Chapter6 from "./Components/Pages/Chapter6/Chapter6";
import Home from "./Components/Pages/Home/Home";

import CARAConfirmationMessage from "Components/Pages/CreateAccount/CARAConfirmationMessage/CARAConfirmationMessage";
import CreateAccount from "Components/Pages/CreateAccount/CreateAccount";
import EmailConfirmationMessage from "Components/Pages/CreateAccount/EmailConfirmationMessage/EmailConfirmationMessage";
import CreatePassword from "Components/Pages/CreatePassword/CreatePassword";
import LinkExpired from "Components/Pages/CreatePassword/LinkExpired/LinkExpired";
import Profile from "Components/Pages/Profile/Profile";
import RequestPasswordReset from "Components/Pages/RequestPasswordReset/RequestPasswordReset";
import RPRConfirmationMessage from "Components/Pages/RequestPasswordReset/RPRConfirmationMessage/RPRConfirmationMessage";
import ResetPassword from "Components/Pages/ResetPassword/ResetPassword";
import ResetPasswordExpired from "Components/Pages/ResetPassword/ResetPasswordExpired/ResetPasswordExpired";
import NotFound from "./Components/Pages/NotFound";

import Contact from "Components/Pages/Contact";
import SampleMethodology from "Components/Pages/SampleMethodology";
import CONFIG from "config";
import { UserProvider } from "Context/UserContext";


export const ROUTES = {
  LOGIN: "/login",
  HOME: "/",
  CREATE_ACCOUNT: "/create-account",
  CREATE_ACCOUNT_CONFIRMATION: "/create-account-confirmation",
  CREATE_PASSWORD: "/create-password/:id",
  CREATE_PASSWORD_EXPIRED: "/create-password-expired",
  REQUEST_ACCESS_CONFIRMATION: "/request-access-confirmation",
  REQUEST_PASSWORD_RESET: "/request-password-reset",
  REQUEST_PASSWORD_RESET_CONFIRMATION: "/request-password-reset-confirmation",
  RESET_PASSWORD: "/reset-password",
  RESET_PASSWORD_EXPIRED: "/reset-password-expired",
  PROFILE: "/profile",
  CONTACT: "/contact",
  CHAPTER_1: "/chapter-1",
  CHAPTER_2: "/chapter-2",
  CHAPTER_3: "/chapter-3",
  CHAPTER_4: "/chapter-4",
  CHAPTER_5: "/chapter-5",
  CHAPTER_6: "/chapter-6",
  SAMPLE_METHODOLOGY: "/sample-methodology",
};

axiosConfig.get("/alive").catch(function (error) {
  console.log(error);
});

const App = () => {
  const location = useNavigate();

  useEffect(() => {
    trackPageView();
  }, [location]);

  function trackPageView() {
    if(CONFIG.USE_GTM) {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: "virtual_pageview",
        pagePath: window?.location?.pathname,
        pageName: document?.title,
      });
    }
  }
  return (
    <ScrollToTop>
      <UserProvider>
        <Routes>
          <Route
            path={ROUTES.LOGIN}
            element={
              <LoginOverlay>
                <Menu showLogo logoColor={VisaLogoColours.BLUE}>
                  <Home />
                </Menu>
              </LoginOverlay>
            }
          />
          <Route
            path={ROUTES.HOME}
            element={
              <PrivateRoute>
                <Menu showLogo logoColor={VisaLogoColours.BLUE}>
                  <Home />
                </Menu>
              </PrivateRoute>
            }
          />
          <Route path={ROUTES.CREATE_ACCOUNT} element={<CreateAccount />} />
          <Route path={ROUTES.CREATE_ACCOUNT_CONFIRMATION} element={<EmailConfirmationMessage />} />

          <Route path={ROUTES.CREATE_PASSWORD} element={<CreatePassword />} />
          <Route path={ROUTES.CREATE_PASSWORD_EXPIRED} element={<LinkExpired />} />

          <Route path={ROUTES.REQUEST_ACCESS_CONFIRMATION} element={<CARAConfirmationMessage />} />

          <Route path={ROUTES.REQUEST_PASSWORD_RESET} element={<RequestPasswordReset />} />
          <Route path={ROUTES.REQUEST_PASSWORD_RESET_CONFIRMATION} element={<RPRConfirmationMessage />} />

          <Route path={ROUTES.RESET_PASSWORD} element={<ResetPassword />} />
          <Route path={ROUTES.RESET_PASSWORD_EXPIRED} element={<ResetPasswordExpired />} />

          <Route
            path={ROUTES.PROFILE}
            element={
              <PrivateRoute>
                <Menu showLogo logoColor={VisaLogoColours.BLUE}>
                  <Profile />
                </Menu>
              </PrivateRoute>
            }
          />
          <Route
            path={ROUTES.CONTACT}
            element={
              <PrivateRoute>
                <Menu showLogo>
                  <Contact />
                </Menu>
              </PrivateRoute>
            }
          />
          <Route
            path={ROUTES.CHAPTER_1}
            element={
              <PrivateRoute>
                <Menu showLogo>
                  <Chapter1 />
                </Menu>
              </PrivateRoute>
            }
          />
          <Route
            path={ROUTES.CHAPTER_2}
            element={
              <PrivateRoute>
                <Menu showLogo>
                  <Chapter2 />
                </Menu>
              </PrivateRoute>
            }
          />
          <Route
            path={ROUTES.CHAPTER_3}
            element={
              <PrivateRoute>
                <Menu showLogo>
                  <Chapter3 />
                </Menu>
              </PrivateRoute>
            }
          />
          <Route
            path={ROUTES.CHAPTER_4}
            element={
              <PrivateRoute>
                <Menu showLogo>
                  <Chapter4 />
                </Menu>
              </PrivateRoute>
            }
          />
          <Route
            path={ROUTES.CHAPTER_5}
            element={
              <PrivateRoute>
                <Menu showLogo>
                  <Chapter5 />
                </Menu>
              </PrivateRoute>
            }
          />
          <Route
            path={ROUTES.CHAPTER_6}
            element={
              <PrivateRoute>
                <Menu showLogo>
                  <Chapter6 />
                </Menu>
              </PrivateRoute>
            }
          />
          <Route
            path={ROUTES.SAMPLE_METHODOLOGY}
            element={
              <PrivateRoute>
                <Menu showLogo>
                  <SampleMethodology />
                </Menu>
              </PrivateRoute>
            }
          />
          <Route path="*" element={<NotFound />} />
        </Routes>
        <Footer />
      </UserProvider>
    </ScrollToTop>
  );
};

export default App;
