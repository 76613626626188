import cn from "classnames";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import layout from "../../../Styles/layout.module.scss";
import typ from "../../../Styles/typography.module.scss";
import style from "./Chapter4.module.scss";

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

import BackgroundGradient from "../../UI/BackgroundGradient/BackgroundGradient";

import ChapterBanner from "../../Layout/ChapterBanner/ChapterBanner";
import Chapter4Person from "./assets/chapter-banner-4.png";

import PercentGraph from "../../UI/PercentGraph/PercentGraph";

import Quote from "../../Layout/Quote/Quote";

import CoasterImageContent from "../../Layout/CoasterImageContent/CoasterImageContent";
import PeopleWall from "./assets/people-wall.jpg";
import PersonLaptopSofa from "./assets/person-laptop-sofa.jpg";

import Video from "Components/UI/Video/Video";
import Video1 from "Videos/Ch4-UK18-Fintech.mp4";

import ChapterTabs from "../../Layout/ChapterTabs/ChapterTabs";
import { chapters } from "../../Layout/ChapterTabs/ChapterTabsConfig";

const Chapter4 = () => {

    gsap.registerPlugin(ScrollTrigger);

    const [chapterState, setChapterState] = useState(chapters);

    useEffect(() => {
        setChapterState(prevState => ({
        ...prevState,
        four: {
            ...prevState.four,
            active: true
        }
        }));
    }, []);

    useEffect(() => {
        setTimeout(() => {
            ScrollTrigger.refresh();
        }, 200)
    }, []);

    return (
        <div>

            <Helmet>
                <title>...with tools to budget day to day</title>
            </Helmet>

            <BackgroundGradient colorFrom="#1434CB" colorTo="#FFFFFF" gradientStart="top top" gradientDuration="2000">

                <ChapterBanner
                    title="...with tools to budget day to day"
                    copy="2 in 3 Gen Zers take proactive action in planning their future and ensuring they are budgeting appropriately; they recognise the security this provides in the short-term (i.e., living within their means) and long-term (saving for rainy days)."
                    image={Chapter4Person}
                    color="white"
                />

                <Quote 
                quote="I am constantly aware of what I can and can’t afford and what I have to do to afford something in the future."
                source="IT, 21"
                color="blue" />

                <section>
                    <div className="inner">

                        <div className={cn(layout.threeCol, layout.threeColGraphs)}>
                            <div>
                                <PercentGraph percent="64" vertical onWhite>
                                    <h4>budget their spending to avoid surprises and to be able to plan projects.</h4>
                                    <p>NET somewhat/completely agree.</p>
                                </PercentGraph>
                            </div>
                            <div>
                                <PercentGraph percent="63" vertical onWhite>
                                    <h4>keep track of their everyday payments to ensure staying on budget.</h4>
                                    <p>NET somewhat/completely agree.</p>
                                </PercentGraph>
                            </div>
                            <div>
                                <PercentGraph percent="64" vertical onWhite>
                                    <h4>save money regularly for rainy days and future projects.</h4>
                                    <p>NET somewhat/completely agree.</p>
                                </PercentGraph>
                            </div>
                        </div>

                    </div>
                </section>

                <Quote 
                quote="As soon as I get my salary, I always think every month how much money I can put aside. I have an excel table that I put my fixed costs into each month, but also think about what events are happening that month that I’ll need to pay for."
                source="DE, 22"
                color="blue" />

            </BackgroundGradient>

            <BackgroundGradient colorFrom="#FFFFFF" colorTo="#FCC015" gradientStart="top top" gradientDuration="500">

                <CoasterImageContent img1={PersonLaptopSofa} img2={PeopleWall}>
                    <h4 className={typ.fontRegular}>
                        Gen Z use digital tools and traditional strategies to budget and build their money. However, there is room for financial services to provide better budgeting tools for this generation.
                    </h4>
                </CoasterImageContent>

                <section>
                    <div className="inner">

                        <div className={cn(layout.threeCol, layout.threeColGraphs)}>
                            <div>
                                <PercentGraph percent="38" vertical>
                                    <h4>use Traditional Bank websites/apps/emails to manage their personal finances.</h4>
                                </PercentGraph>
                            </div>
                            <div>
                                <PercentGraph percent="35" vertical>
                                    <h4>of fintech users, use Fintech websites/apps/emails to manage their personal finances.</h4>
                                </PercentGraph>
                            </div>
                            <div>
                                <PercentGraph percent="22" vertical>
                                    <h4>use manual budgeting tools such as Excel/Google Sheets, pen and paper.</h4>
                                </PercentGraph>
                            </div>
                        </div>

                    </div>
                </section>

                <section>
                    <div className="inner">

                        <div className={style.videoCentral}>
                            
                            <Video src={Video1} />

                            <div className={layout.videoText}>
                                <h4>UK, 18</h4>
                                <p>This Gen Zer uses a Fintech app to track and categorise her spending and create savings pots.</p>
                            </div>
                        </div>

                    </div>
                </section>

            </BackgroundGradient>

            <ChapterTabs chapters={chapterState} />
        </div>
        
    )
}

export default Chapter4