import create from "zustand";

export default create((set) => ({
  idle: false,
  setIdle: (isIdle) =>
    set((state) => ({
      idle: isIdle,
    })),
  profile: {},
  setProfile: (profile) =>
    set((state) => ({
      profile: profile,
    })),
}));
