import { sanitizeUrl } from "@braintree/sanitize-url";
import { ROUTES } from "App";
import Button from "Components/Forms/Button";
import { Checkbox, TextInput } from "Components/Forms/Fields";
import Messages, { MESSAGE_TYPES } from "Components/Forms/Messages";
import CONFIG from "config";
import { API_SHORTCODES } from "Constants";
import UserContext from "Context/UserContext";
import { Form, Formik } from "formik";
import { Login } from "Helpers/login";
import { ERROR_MESSAGES } from "Helpers/messages";
import { useContext, useRef, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { useNavigate } from "react-router-dom";
import axiosConfig from "Services/axiosConfig";
import userState from "Stores/User";
import layout from "Styles/layout.module.scss";
import * as Yup from "yup";

const CreatePasswordForm = () => {
  const navigate = useNavigate();
  const recaptchaRef = useRef(null);
  const [isLoggedIn, setIsLoggedIn] = useContext(UserContext);
  const { setProfile } = userState();
  const [errors, setErrors] = useState([]);

  const validationSchema = Yup.object({
    password: Yup.string().required("Required"),
    confirmPassword: Yup.string().oneOf([Yup.ref("password"), null], "Passwords must match"),
  });

  const sanitisedUrl = sanitizeUrl(window.location.href);
  const url = new URL(sanitisedUrl);
  const code = url.searchParams.get("code");
  const id = url.pathname.split("/").pop();
  const email = url.searchParams.get("email");

  const onSubmit = async ({ password, confirmPassword }) => {
    let token;

    if (recaptchaRef?.current) {
      recaptchaRef.current.reset();
      token = await recaptchaRef.current.executeAsync();
    }

    setErrors([]);

    const headers = {
      "g-recaptcha-response": token,
    };
    const payload = {
      userId: id,
      code,
      password,
      confirmPassword,
    };
    try {
      await axiosConfig.post(`/identity/users/confirm-account`, payload, { headers });

      let authToken
      if (recaptchaRef?.current) {
        recaptchaRef.current.reset();
        authToken = await recaptchaRef.current.executeAsync();
      }

      const loginRes = await Login(email, password, authToken);
      const { returnedObject } = loginRes;

      if (returnedObject) {
        setProfile(returnedObject);
        setIsLoggedIn(true);
      }

      navigate(ROUTES.HOME, { replace: true });
      
    } catch (error) {
      const errorMessages =
        error.response?.data?.errorMessages ||
        error.response?.response?.statusText ||
        `${ERROR_MESSAGES.GENERIC_ERROR}`;
      if (errorMessages?.includes(API_SHORTCODES.INVALID_TOKEN)) {
        navigate(ROUTES.PASSWORD_RESET_EXPIRED + "/?email=" + email);
      }
      setErrors([errorMessages]);
    }
  };

  return (
    <Formik
      enableReinitialize
      initialValues={{
        password: "",
        confirmPassword: "",
      }}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ handleBlur, handleChange, handleSubmit, values, isSubmitting }) => (
        <>
          <Form onSubmit={handleSubmit}>
            <Messages messages={errors} type={MESSAGE_TYPES.ERROR} />
            <div className={layout.formCols}>
              <div>
                <TextInput
                  type="password"
                  label="password"
                  placeholder="Password"
                  name="password"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  required
                />
              </div>
              <div>
                <TextInput
                  type="password"
                  label="confirmPassword"
                  placeholder="Confirm password"
                  name="confirmPassword"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  required
                />
              </div>
            </div>
            <Checkbox
              id="acceptedFutureCommunications"
              name="acceptedFutureCommunications"
              value={values.acceptedFutureCommunications}
              checked={values.acceptedFutureCommunications}
              onChange={handleChange}
            >
              By checking this box, you agree to receive marketing communications about Visa business products and
              services. You can unsubscribe at any time. For more information on how Visa collects, uses, and protects
              your personal information, please review our{" "}
              <a href="https://usa.visa.com/legal/privacy-policy.html" target="_blank" rel="noopener noreferrer">
                Global Privacy Notice
              </a>
            </Checkbox>
            <div>
              <Button loading={isSubmitting} type="submit" appearance="primaryBlue" withIcon>
                Confirm and sign in
              </Button>
            </div>
          </Form>
          <ReCAPTCHA ref={recaptchaRef} size="invisible" sitekey={CONFIG.RECAPTCHA_SITE_KEY} />
        </>
      )}
    </Formik>
  );
};

export default CreatePasswordForm;
