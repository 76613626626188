import React from "react";
import { Link } from "react-router-dom";
import logoBlue from "Images/Vectors/visa-logo-blue.svg";
import logoWhite from "Images/Vectors/visa-logo-white.svg";
import styles from "./VisaLogo.module.css";

export const VisaLogoColours = {
  BLUE: logoBlue,
  WHITE: logoWhite,
};

export const VisaLogo = ({ logo = VisaLogoColours.BLUE }) => {
  return (
    <Link to="/">
      <img className={styles.visaLogo} src={logo} alt="VISA" />
    </Link>
  );
};
