import { useRef, useEffect } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

const BackgroundGradient = ({children, colorFrom, colorTo, gradientStart, gradientDuration}) => {

    gsap.registerPlugin(ScrollTrigger);
    const gradientWrapperRef = useRef();

    useEffect(() => {
        var gtl = gsap.timeline({
            scrollTrigger: {
            trigger: gradientWrapperRef.current,
            start: gradientStart,
            end: "+="+gradientDuration,
            scrub: 1
            } 
        });

        gtl.to(gradientWrapperRef.current, { background: colorTo, duration: 1, }, "-=.5");

        return () => {
            gtl.scrollTrigger.kill();
            gtl.kill();
        };
    }, [colorTo]);

    return (
        <div ref={gradientWrapperRef} style={{background: colorFrom}}>
            {children}
        </div>
    )
}

export default BackgroundGradient