import { ROUTES } from "App";
import Button from "Components/Forms/Button";
import { Checkbox, TextInput } from "Components/Forms/Fields";
import Messages, { MESSAGE_TYPES } from "Components/Forms/Messages";
import CONFIG from "config";
import { API_SHORTCODES } from "Constants";
import { Form, Formik } from "formik";
import { ERROR_MESSAGES } from "Helpers/messages";
import { useRef, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { useNavigate } from "react-router-dom";
import axiosConfig from "Services/axiosConfig";
import * as Yup from "yup";

const CreateAccountForm = ({ handleChange }) => {
  const [errors, setErrors] = useState([]);
  let navigate = useNavigate();

  const recaptchaRef = useRef(null);
  const validationSchema = Yup.object({
    firstName: Yup.string().required("Required"),
    lastName: Yup.string().required("Required"),
    email: Yup.string().required("Required"),
  });

  const handleCreateAccountResponse = (res) => {
    setErrors([]);
    let statusCode = res?.data?.statusCode;
    switch (statusCode) {
      case 200:
        navigate(ROUTES.CREATE_ACCOUNT_CONFIRMATION);
        break;
      case 202:
        handleChange(true)
        break;
      default:
        const errorMessages =
          res?.data?.errorMessages ||
          res?.response?.statusText ||
          `${ERROR_MESSAGES.GENERIC_ERROR}`;
        setErrors([errorMessages]);
        if (recaptchaRef?.current) {
          recaptchaRef.current.reset();
        }
    }
  };

  const handleErrorResponse = (error) => {
    let errors;
    const errorMessages =
      error.response?.data?.errorMessages ||
      error.response?.response?.statusText ||
      `${ERROR_MESSAGES.GENERIC_ERROR}`;
    if (errorMessages?.includes(API_SHORTCODES.DISALLOWED_DOMAIN)) {
      errors = [
        <>
          We are unable to give you access. If you have used a personal email
          address, please use a business email address to create your account.
          Please contact{" "}
          <a href="mailto:EUResearchandInsights@visa.com">EUResearchandInsights@visa.com</a> for
          assistance.
        </>,
      ];
    } else if (errorMessages?.includes(API_SHORTCODES.USER_EXISTS)) {
      errors = [
        <>
          This email address is already registered for Visa Navigate
          Experiences. Please sign in or request a password reset via the Sign
          In link below. Please contact{" "}
          <a href="mailto:EUResearchandInsights@visa.com">EUResearchandInsights@visa.com</a> for further
          assistance.
        </>,
      ];
    } else {
      errors = errorMessages;
    }
    setErrors(errors);
    if (recaptchaRef?.current) {
      recaptchaRef.current.reset();
    }
  };


  const onCreateAccountSubmit = async (values) => {
    let user;
    let endpoint;
    let token;

    if (recaptchaRef?.current) {
      recaptchaRef.current.reset();
      token = await recaptchaRef.current.executeAsync();
    }

    endpoint = "identity/users/basic-user";
    user = {
      email: values.email,
      surname: values.lastName,
      forename: values.firstName,
      acceptedFutureCommunications: values.acceptedFutureCommunications,
    };

    setErrors([]);

    return axiosConfig
      .post(`/${endpoint}`, user, {
        headers: {
          "g-recaptcha-response": token,
        },
      })
      .then(handleCreateAccountResponse)
      .catch(handleErrorResponse)
  };

  return (
    <Formik
      initialValues={{
        firstName: "",
        lastName: "",
        email: "",
        acceptedFutureCommunications: false,
      }}
      validationSchema={validationSchema}
      onSubmit={onCreateAccountSubmit}
      enableReinitialize
    >
      {({ handleBlur, handleChange, values, isSubmitting }) => (
        <>
          <Form>
            <Messages messages={errors} type={MESSAGE_TYPES.ERROR} />
            <TextInput
              type="text"
              label="firstName"
              placeholder="First name"
              name="firstName"
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <TextInput
              type="text"
              label="lastName"
              placeholder="Last name"
              name="lastName"
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <TextInput
              type="email"
              label="email"
              placeholder="Email address"
              name="email"
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <Checkbox
              id="acceptedFutureCommunications"
              name="acceptedFutureCommunications"
              value={values.acceptedFutureCommunications}
              checked={values.acceptedFutureCommunications}
              onChange={handleChange}
            >
              By checking this box, you agree to receive marketing
              communications about Visa business products and services. You can
              unsubscribe at any time. For more information on how Visa
              collects, uses, and protects your personal information, please
              review our{" "}
              <a
                href="https://usa.visa.com/legal/privacy-policy.html"
                target="_blank"
                rel="noopener noreferrer"
              >
                Global Privacy Notice
              </a>
            </Checkbox>
            <div>
              <Button loading={isSubmitting} type="submit" appearance="primaryBlue" withIcon>
                Create account
              </Button>
            </div>
          </Form>
          <ReCAPTCHA
            ref={recaptchaRef}
            size="invisible"
            sitekey={CONFIG.RECAPTCHA_SITE_KEY}
          />
        </>
      )}
    </Formik>
  );
};

export default CreateAccountForm;
