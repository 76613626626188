import classNames from "classnames";
import { useState } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

import layout from "../../../Styles/layout.module.scss";
import CARAForm from "./CARAForm";
import CreateAccountForm from "./CreateAccountForm";

import CreateAccountSVG from "Images/Vectors/brandicon-createaccount.svg";


const CreateAccount = () => {
  const [verifyUserDomain, setVerifyUserDomain] = useState(false);

  const handleChange = bool => {
    setVerifyUserDomain(bool);
  };

  return (
    <>
      <section>
        <Helmet>
          <title>Create a Visa Insights account</title>
        </Helmet>
        <div className="inner">
          {!verifyUserDomain ? 
          
            <div className={layout.functionalPage}>
              <img src={CreateAccountSVG} alt="" />
              <h1>Create a Visa Insights account</h1>
              <p>
                Already have a Visa Insights account?
                <br />
                <Link to="/">Sign in here</Link>
              </p>

              <CreateAccountForm handleChange={handleChange} />
            </div> 
          
          :

            <div className={classNames(layout.functionalPage, layout.wide)}>
                  <img src={CreateAccountSVG} alt="" />
                  <h1>Request access</h1>
                  <p>Please submit some further information so we can review your request for a Visa Insights account.</p>
                  <CARAForm />
              </div>
          
          }
        </div>
      </section>
    </>
  );
};

export default CreateAccount;
