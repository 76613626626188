import React from "react";
import { Helmet } from "react-helmet";
import typographyStyles from "Styles/typography.module.scss";
import styles from "./NotFound.module.scss";
import NotFoundImage from "Images/404/404-ERROR_f7f7f7_1569586543.jpg";
import Button from "Components/Forms/Button";

const NotFound = () => {

  return (
    <>
      <Helmet>
        <title>Page Not Found</title>
      </Helmet>
        <div className={styles.pageContainer}>
          <img src={NotFoundImage} alt="404 Not Found" />
          <div className={styles.content}>
            <p className={typographyStyles.bodyCopy1}>Sorry, we can't find the page you were looking for but there is plenty of content to explore in the main menu.</p>
            <Button to="/">View the home page</Button>
          </div>
        </div>
    </>
  );
};

export default NotFound;
