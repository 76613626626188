import { useRef, useEffect } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import style from "./MainBanner.module.scss";

import HeroGuy from "./assets/hero-guy-desktop.png";
import HeroGirl from "./assets/hero-girl-desktop.png";
import ScrollIndicator from "Components/UI/ScrollIndicator/ScrollIndicator";

const MainBanner = () => {

  gsap.registerPlugin(ScrollTrigger);

  const mainBannerSectionRef = useRef();
  const bannerColumnRef = useRef();
  const wordContainerWrapperRef = useRef();
  const wordContainerRef = useRef();

  const word2Ref = useRef();
  const word3Ref = useRef();
  const word4Ref = useRef();
  const word5Ref = useRef();
  const word6Ref = useRef();

  const heroGuyRef = useRef();
  const heroGirlRef = useRef();
  const bodyCopyWrapperRef = useRef();

  const scrollIndicatorRef = useRef();

  useEffect(() => {

    if(window.innerWidth >= 1025) {

      var tl = gsap.timeline({
        scrollTrigger: {
          trigger: mainBannerSectionRef.current,
          pin: true,
          start: "top top",
          end: "+=3000",
          scrub: 1
        } 
      });
  
      tl.to(wordContainerWrapperRef.current, { width: "50%", duration: 1, });
      tl.to(wordContainerRef.current, { fontSize: "90px", lineHeight: "100px", duration: 1, });
      tl.to(scrollIndicatorRef.current, { opacity: "0", duration: 1, }, "-=1");
      tl.to(word2Ref.current, { opacity: 1, duration: 1, }, "-=.5");
      tl.to(word3Ref.current, { opacity: 1, duration: 1, }, "-=.5");
      tl.to(word4Ref.current, { opacity: 1, duration: 1, }, "-=.5");
      tl.to(word5Ref.current, { opacity: 1, duration: 1, }, "-=.5");
      tl.to(word6Ref.current, { opacity: 1, duration: 1, }, "-=.5");
      tl.to(word6Ref.current, { opacity: 1, duration: 1, }, "-=.5");
      tl.to(bannerColumnRef.current, { transform: "translateY(-75vh)", duration: 3, }, "-=.5");
      tl.to(heroGirlRef.current, {transform: "translateX(0px)", opacity: 1, duration: 3,}, "-=3");

    } else {

      var tlm = gsap.timeline({
        scrollTrigger: {
          trigger: mainBannerSectionRef.current,
          pin: true,
          start: "top top",
          end: "+=3000",
          scrub: true
        } 
      });

      tlm.to(wordContainerRef.current, { fontSize: "50px", lineHeight: "50px", duration: 1, });
      tlm.to(scrollIndicatorRef.current, { opacity: "0", duration: 1, }, "-=1");
      tlm.to(word2Ref.current, { opacity: 1, duration: 1, }, "-=.5");
      tlm.to(word3Ref.current, { opacity: 1, duration: 1, }, "-=.5");
      tlm.to(word4Ref.current, { opacity: 1, duration: 1, }, "-=.5");
      tlm.to(word5Ref.current, { opacity: 1, duration: 1, }, "-=.5");
      tlm.to(word6Ref.current, { opacity: 1, duration: 1, }, "-=.5");
      tlm.to(word6Ref.current, { opacity: 1, duration: 1, }, "-=.5");
      tlm.to(heroGuyRef.current, { height: "calc(100% - 50px)", duration: 2, }, "-=3");
      tlm.to(heroGuyRef.current, { left: "30px", duration: 2, }, "-=3");
      tlm.to(wordContainerWrapperRef.current, { transform: "translateY(-100vh)", duration: 3, }, "-=.5");
      tlm.to(bodyCopyWrapperRef.current, { transform: "translateY(-75vh)", duration: 3, }, "-=3");
      tlm.to(heroGirlRef.current, {transform: "translateX(0px)", opacity: 1, duration: 3,}, "-=3");

    }

  }, []);

  useEffect(() => {

    var tl2 = gsap.timeline({
      scrollTrigger: {
        start: "3000",
        end: "+=600",
        scrub: true
      } 
    });

    tl2.to(mainBannerSectionRef.current, { background: "#1434CB", duration: 1, });
  }, []);

  return (
    <section className={style.mainBannerSection} ref={mainBannerSectionRef}>
      <div className="inner">

        <div className={style.mainBanner}>

          <div className={style.bannerColumn} ref={bannerColumnRef}>

            <div ref={wordContainerWrapperRef} className={style.h1Wrapper}>

              <h1 ref={wordContainerRef}>
                <span className={style.word1}>Gen Z </span> 
                <span ref={word2Ref}>and </span> 
                <span ref={word3Ref}>Finance: </span> 
                <span ref={word4Ref}>A </span> 
                <span ref={word5Ref}>New </span> 
                <span ref={word6Ref}>Paradigm</span>
              </h1>

            </div>

            <div ref={bodyCopyWrapperRef} className={style.bodyCopyWrapper}>
              <h4>
                Generation Z  is redefining the financial future. You can use this space to learn more about Gen Z and money; their attitudes towards savings and investment, how they interact with financial products, and what role financial brands play in their lives.
              </h4>
              <p>
                *All data on this site represents the pan-EU average across UK, DE, PL and IT.
              </p>
            </div>

          </div>

          <div className={style.imagewrapper}>
            <img src={HeroGuy} ref={heroGuyRef} className={style.heroGuy} alt="" />
            <img src={HeroGirl} ref={heroGirlRef} className={style.heroGirl} alt="" />
          </div>

        </div>

      </div>

      <div ref={scrollIndicatorRef} className={style.scrollIndicator}>
        <ScrollIndicator />
      </div>

    </section>
  )
}

export default MainBanner