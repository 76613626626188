import cn from "classnames";
import { Helmet } from "react-helmet";
import layout from "../../../Styles/layout.module.scss";

import IconPW from "Images/Vectors/brandicon-pw.svg";
import CreatePasswordForm from "./CreatePasswordForm/CreatePasswordForm";

const CreatePassword = () => {
  return (
    <section>

        <Helmet>
          <title>Create password</title>
        </Helmet>

        <div className="inner">

            <div className={cn(layout.functionalPage, layout.wide)}>

                <img src={IconPW} alt="Create password" />
                <h1>Create password</h1>

                <CreatePasswordForm />

            </div>

        </div>
    </section>
  )
}

export default CreatePassword