import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import userState from "Stores/User";
import ThankYou from "./ThankYou";
import axiosConfig from "Services/axiosConfig";
import ContactForm from "./ContactForm/ContactForm";

function Contact() {
  const { setProfile } = userState();
  const [submitSuccess, setSubmitSuccess] = useState(false);
  
  useEffect(() => {
    const fetchProfile = async () => {
        const { data } = await axiosConfig.get(`/my-user-info`);
        const { returnedObject } = data;
        const { profile } = returnedObject;
        if (profile) {
          setProfile(profile);
        }
    }
    fetchProfile();
  }, [setProfile]);

  return (
    <>
      <Helmet>
        <title>Connect with us</title>
      </Helmet>
      
        {!submitSuccess ? (
          <ContactForm setSubmitSuccess={setSubmitSuccess} />
        ) : (
          <ThankYou />
        )}
    </>
  );
}

export default Contact;
