import { useEffect, useRef } from "react";
import style from "./PercentGraph.module.scss";
import typ from "../../../Styles/typography.module.scss";
import cn from "classnames";

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

const PercentGraph = ({ children, vertical, percent, size, onWhite }) => {

  gsap.registerPlugin(ScrollTrigger);

  const graphRef = useRef();
  const circleRef = useRef();
  const numberRef = useRef();

  var fillPercent = percent
  var fillPercentCircleValue
  var fillPercentTextValue = fillPercent;

  var circleSize;
  var circleR;
  var circleCXCY;
  var circleOffset;

  // stroke dash array is 2 * pi * r

  if(window.innerWidth < 768) {
    circleSize = 125;
    circleR = 50;
    circleCXCY = 62.5;
    circleOffset = 314;
  } else if(!size || size === "small") {
    circleSize = 250;
    circleR = 100;
    circleCXCY = 125;
    circleOffset = 628;
  } else if(size === "med") {
    circleSize = 375;
    circleR = 150;
    circleCXCY = 187.5;
    circleOffset = 942;
  }

  fillPercentCircleValue = circleOffset * (1 - (fillPercent / 100))

  useEffect(() => {
    var pgtl = gsap.timeline({
        scrollTrigger: {
        trigger: graphRef.current,
        start: "top 50%",
        scrub: false
        } 
    });
    
    pgtl.to(circleRef.current, { strokeDashoffset: fillPercentCircleValue, duration: 1, }, "0");
    pgtl.to(numberRef.current, { textContent: fillPercentTextValue, snap: { textContent: 1 }, fillPercentCircleValue, duration: 1, }, "0");

    return () => {
      pgtl.scrollTrigger.kill();
      pgtl.kill();
    };
  }, []);

  return (
    <div className={cn(style.percentGraphWrapper, vertical && style.vertical)}>

      <div className={style.circleWrapper}>
        <svg width={circleSize} height={circleSize} ref={graphRef}>
            <circle className={cn(style.circleBg, {[style.onWhite] : onWhite})} r={circleR} cy={circleCXCY} cx={circleCXCY} fill="none"></circle>
            <circle className={style.circle} r={circleR} cy={circleCXCY} cx={circleCXCY} fill="none" strokeDashoffset={circleOffset} strokeDasharray={circleOffset} ref={circleRef}></circle>
        </svg>
        <div className={cn(style.percentageValue, typ.fontMedium)}>
          <span ref={numberRef}>0</span>%
        </div>
      </div>

      <div className={style.textArea}>
        {children}
      </div>

    </div>
  )
}

export default PercentGraph