import React, { useContext, useEffect } from "react"
import LoginForm from "./LoginForm/LoginForm"
import overlayStyles from "../../../Styles/overlay.module.scss"
import UserContext from "Context/UserContext"
import { useNavigate } from "react-router-dom"

/* comment */

function LoginOverlay({ children }) {
  const [isLoggedIn, setIsLoggedIn] = useContext(UserContext)
  const navigate = useNavigate()

  useEffect(() => {
    if (!isLoggedIn) {
      // document.body.style.overflow = "hidden";
      document.body.style.overflow = "auto"
    } else {
      document.body.style.overflow = "auto"
      navigate("/")
    }
    return function cleanup() {
      document.body.style.overflow = "auto"
    }
  }, [isLoggedIn])

  return (
    <>
      <div className={`${overlayStyles.overlay}`}>
        {!isLoggedIn && (
          <div className={overlayStyles.overlayWrapper}>
            <LoginForm onLogin={() => setIsLoggedIn(true)} />
          </div>
        )}
      </div>
      {children}
    </>
  )
}

export default LoginOverlay
