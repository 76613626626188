import Chapter1 from "./assets/chapter-1.png";
import Chapter2 from "./assets/chapter-2.png";
import Chapter3 from "./assets/chapter-3.png";
import Chapter4 from "./assets/chapter-4.png";
import Chapter5 from "./assets/chapter-5.png";
import Chapter6 from "./assets/chapter-6.png";

export const chapters = {
    one: {
        image: Chapter1,
        title: "Gen Z: The product of their time",
        linkText: "Chapter 1",
        url: "/chapter-1",
        active: false
    },
    two: {
        image: Chapter2,
        title: "The pursuit of financial independence",
        linkText: "Chapter 2",
        url: "/chapter-2",
        active: false
    },
    three: {
        image: Chapter3,
        title: "Eyes firmly on their future...",
        linkText: "Chapter 3",
        url: "/chapter-3",
        active: false
    },
    four: {
        image: Chapter4,
        title: "...with tools to budget day to day",
        linkText: "Chapter 4",
        url: "/chapter-4",
        active: false
    },
    five: {
        image: Chapter5,
        title: "Buy Now Pay Later: The new version of credit?",
        linkText: "Chapter 5",
        url: "/chapter-5",
        active: false
    },
    six: {
        image: Chapter6,
        title: "Building relationships with Gen Z",
        linkText: "Chapter 6",
        url: "/chapter-6",
        active: false
    },
};