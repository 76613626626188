import cn from "classnames";
import { gsap } from "gsap";
import { Helmet } from "react-helmet";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useEffect, useState } from "react";
import layout from "../../../Styles/layout.module.scss";
import style from "./Chapter5.module.scss";

import ChapterBanner from "../../Layout/ChapterBanner/ChapterBanner";
import ChapterTabs from "../../Layout/ChapterTabs/ChapterTabs";
import { chapters } from "../../Layout/ChapterTabs/ChapterTabsConfig";
import Quote from "../../Layout/Quote/Quote";
import BackgroundGradient from "../../UI/BackgroundGradient/BackgroundGradient";
import PercentGraph from "../../UI/PercentGraph/PercentGraph";

import Video from "Components/UI/Video/Video";
import Video1 from "Videos/Ch5-UK18-BNPL.mp4";

import Chapter5Person from "./assets/chapter-banner-5.png";

const Chapter5 = () => {

    gsap.registerPlugin(ScrollTrigger);

    const [chapterState, setChapterState] = useState(chapters);

    useEffect(() => {
        setChapterState(prevState => ({
        ...prevState,
        five: {
            ...prevState.five,
            active: true
        }
        }));
    }, []);

    useEffect(() => {
        setTimeout(() => {
            ScrollTrigger.refresh();
        }, 200)
    }, []);

    return (
        <div>

            <Helmet>
                <title>Buy Now Pay Later: The new version of credit?</title>
            </Helmet>

            <BackgroundGradient colorFrom="#1434CB" colorTo="#FFFFFF" gradientStart="top top" gradientDuration="2000">

                <ChapterBanner
                    title="Buy Now Pay Later: The new version of credit?"
                    copy="31% of Gen Z use Buy Now Pay Later (BNPL). BNPL is fast becoming the credit product of this generation - while credit card usage may grow, the fact that BNPL is many Gen Z's first experience of credit will dictate how simple credit card and credit offerings will need to be to appeal to this generation."
                    image={Chapter5Person}
                    color="white"
                />

                <section>
                    <div className="inner">

                        <div className={layout.twoCol}>

                            <PercentGraph percent="25" onWhite>
                                <h4>of Gen Z use BNPL regularly as a payment method.</h4>
                            </PercentGraph>

                            <PercentGraph percent="65" onWhite>
                                <h4>Among the 13% of Gen Z who use BNPL to manage their finances, 65% find it very/extremely useful.</h4>
                            </PercentGraph>

                        </div>

                    </div>
                </section>

                <section>
                    <div className="inner">

                        <h3 className={layout.titleChunk}>What Makes Buy Now Pay Later Different From Other Providers?</h3>

                    </div>
                </section>

                <Quote 
                quote="Easier to make big purchases and not pull out a lot of money at one time."
                source="DE, 21"
                color="blue" />

                <section>
                    <div className="inner">

                        <div className={layout.twoCol}>

                            <div>
                                <Video src={Video1} />
                                <div className={cn(style.textBox, layout.videoText)}>
                                    <h4>UK, 18</h4>
                                    <p>One of our UK Gen Zers discusses the benefits of Buy Now Pay Later schemes, particularly for people of his age.</p>
                                </div>
                            </div>
                            <div>
                                <h4>
                                    Gen Zers acknowledge BNPL schemes are useful for:
                                </h4>
                                <ul className="list-normal">
                                    <li>
                                        <p>
                                            When they are cash-strapped e.g. a big ticket item they need urgently but don't have the funds to pay for outright at the time.
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            Trialling several products at once for free (e.g. clothes) with the intent to only pay for the items they keep.
                                        </p>
                                    </li>
                                </ul>
                            </div>

                        </div>

                    </div>
                </section>

            </BackgroundGradient>

            <BackgroundGradient colorFrom="#FFFFFF" colorTo="#FCC015" gradientStart="top top" gradientDuration="200">

                <section>
                    <div className="inner">

                        <h3 className={layout.titleChunk}>
                            Some are wary of the dangers of being in debt using Buy Now Pay Later, a barrier to adoption by Gen&nbsp;Z.
                        </h3>

                    </div>
                </section>

                <Quote 
                quote="I know people who have ended up having to pay back a lot of money to Buy Now Pay Later schemes because they spend money and then they have to pay more interest later on… I wouldn’t want to do that personally."
                source="DE, 21"
                color="blue"
                closeWhite={true} />

            </BackgroundGradient>

            <ChapterTabs chapters={chapterState} />
        </div>
        
    )
}

export default Chapter5