import { useRef, useEffect } from "react";
import style from "./RankingBar.module.scss";
import typ from "../../../Styles/typography.module.scss";
import cn from "classnames";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

const RankingBar = () => {

  gsap.registerPlugin(ScrollTrigger);

  const rankingBarRef = useRef();
  const rankingItemRef1 = useRef();
  const rankingItemRef2 = useRef();
  const rankingItemRef3 = useRef();
  const rankingItemRef4 = useRef();
  const rankingItemRef5 = useRef();
  const rankingItemRef6 = useRef();
  const rankingItemRef7 = useRef();

  useEffect(() => {
    var tlrb = gsap.timeline({
      scrollTrigger: {
        trigger: rankingBarRef.current,
        pin: false,
        start: "top center",
        end: "+=600",
        scrub: 1
      } 
    });

    tlrb.to(rankingItemRef1.current, { transform: "translateX(0px)", duration: 1, }, "-=.5");
    tlrb.to(rankingItemRef2.current, { transform: "translateX(0px)", duration: 1, }, "-=.5");
    tlrb.to(rankingItemRef3.current, { transform: "translateX(0px)", duration: 1, }, "-=.5");
    tlrb.to(rankingItemRef4.current, { transform: "translateX(0px)", duration: 1, }, "-=.5");
    tlrb.to(rankingItemRef5.current, { transform: "translateX(0px)", duration: 1, }, "-=.5");
    tlrb.to(rankingItemRef6.current, { transform: "translateX(0px)", duration: 1, }, "-=.5");
    tlrb.to(rankingItemRef7.current, { transform: "translateX(0px)", duration: 1, }, "-=.5");

    return () => {
      tlrb.scrollTrigger.kill();
      tlrb.kill();
    };
  }, []);

  return (
    <section className={style.rankingBar} ref={rankingBarRef}>
      <div className={cn(style.rankingBarInner, "inner")}>
        
        <h3 className={typ.title}>Topics ranked by ease of discussing with peers and family...</h3>

        <div className={style.rankingBarWrapper}>

          <h5>Very comfortable</h5>

          <div className={style.rankingBarWrapperInner}>

            <div className={cn(style.itemContainer, style.left)}>
              <div ref={rankingItemRef2}><h5>Finance &#x1F911;</h5></div>
              <div ref={rankingItemRef4}><h5>Sex &#x1F346;</h5></div>
              <div ref={rankingItemRef6}><h5>Politics &#x1f5f3;</h5></div>
            </div>

            <div className={style.barWrapper}>
              <div className={cn(style.barEnd, style.barEndTop)}></div>
              <div className={style.bar}></div>
              <div className={cn(style.barEnd, style.barEndBottom)}></div>
            </div>

            <div className={cn(style.itemContainer, style.right)}>
              <div ref={rankingItemRef1}><h5>Health &#x1F9E1;</h5></div>
              <div ref={rankingItemRef3}><h5>Mental Health &#x1F914;</h5></div>
              <div ref={rankingItemRef5}><h5>Religion &#x1f64f;</h5></div>
              <div ref={rankingItemRef7}><h5>Death &#x1F480;</h5></div>
            </div>

          </div>

          <h5>Not at all comfortable</h5>

        </div>

      </div>
    </section>
  )
}

export default RankingBar