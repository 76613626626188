import { useField } from "formik";
import Select from "react-select";
import styles from './fields.module.scss';
import cn from "classnames";

export const TextInput = ({ label, ...rest }) => {
  // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
  // which we can spread on <input> and alse replace ErrorMessage entirely.
  const [field, meta] = useField(rest);
  return (
    <div className={cn(styles.textInput, { [styles.errorWrapper] : meta.touched && meta.error })}>

      <label htmlFor={rest.id || rest.name}>{label}</label>
      <input id={rest.id || rest.name} {...field} {...rest} />
      
      {meta.touched && meta.error ? 
        <div className={styles.error}>{meta.error}</div> 
        : 
        null
      }

      {rest.helptext ? <small>{rest.helptext}</small> : null}

    </div>
  );
};

export const TextArea = ({ label, ...rest }) => {
  // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
  // which we can spread on <input> and alse replace ErrorMessage entirely.
  const [field, meta] = useField(rest);
  return (
    <div className={cn(styles.textArea, { [styles.errorWrapper] : meta.touched && meta.error })}>
      <label htmlFor={rest.id || rest.name}>{label}</label>
      <textarea id={rest.id || rest.name} {...field} {...rest} />
      {meta.touched && meta.error ? <div className={styles.error}>{meta.error}</div> : null}
      {rest.helptext ? <small>{rest.helptext}</small> : null}
    </div>
  );
};

export const SelectDropdown = ({ label, ...props }) => {
  // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
  // which we can spread on <input> and alse replace ErrorMessage entirely.
  const [field, meta, helpers] = useField(props.name);
  return (
    <>
      <Select
        value={field.value}
        onChange={(value) => helpers.setValue(value)}
        onBlur={() => helpers.setTouched(true)}
        {...props}
      />
      {meta.touched && meta.error ? <div className={styles.error}>{meta.error}</div> : null}
      {props.helptext ? <small>{props.helptext}</small> : null}
    </>
  );
};

export const Checkbox = ({ value, id, name, children, checked, onChange }) => {
  const checkbox = (
    <>
      <input
        type="checkbox"
        onChange={onChange}
        checked={checked}
        value={value}
        aria-checked={checked}
        id={id}
        name={name}
      />
      <label htmlFor={id}>
        <label className={styles.checboxLabel}>{children}</label>
      </label>
    </>
  );

  return <div className={styles.checkbox}>{checkbox}</div>;
};
