import React, { useState, createRef, useMemo } from "react";
import * as Yup from "yup";
import ReCAPTCHA from "react-google-recaptcha";
import countryList from "react-select-country-list";
import { Form, Formik } from "formik";
import {
  organisationTypeOptions,
  organisationSizeOptions,
} from "./ContactForm.schemas";
import { ERROR_MESSAGES } from "Helpers/messages";
import axiosConfig from "Services/axiosConfig";
import userState from "Stores/User";

import { SelectDropdown, TextArea, TextInput } from "Components/Forms/Fields";
import Button from "Components/Forms/Button";
import CONFIG from "config";
import formStyles from "Styles/forms.module.scss";
import pageStyles from "Styles/page.module.scss";
import Messages, { MESSAGE_TYPES } from "Components/Forms/Messages";

const validationSchema = Yup.object({
  forename: Yup.string().required("Required"),
  surname: Yup.string().required("Required"),
  jobTitle: Yup.string().required("Required"),
  companyName: Yup.string().required("Required"),
  email: Yup.string()
    .email("Please enter a valid email address")
    .required("Required"),
  organisationType: Yup.object()
    .shape({
      value: Yup.string(),
      label: Yup.string(),
    })
    .required("Required")
    .nullable(),
  organisationSize: Yup.object()
    .shape({
      value: Yup.string(),
      label: Yup.string(),
    })
    .required("Required")
    .nullable(),
  country: Yup.object()
    .shape({
      value: Yup.string(),
      label: Yup.string(),
    })
    .required("Required")
    .nullable(),
  freeText: Yup.string().required("Required"),
});

function ContactForm({ setSubmitSuccess }) {
  const [errors, setErrors] = useState([]);
  const recaptchaRef = createRef();
  const { profile } = userState();
  const countryOptions = useMemo(() => countryList().getData(), []);

  const handleLoginResponse = (res) => {
    let statusCode = res?.data?.statusCode;
    switch (statusCode) {
      case 200:
        setSubmitSuccess(true);
        break;
      default:
        const errorMessages =
          res?.data?.errorMessages ||
          res?.response?.statusText ||
          `${ERROR_MESSAGES.GENERIC_ERROR}`;
        setErrors([errorMessages]);
        if (recaptchaRef?.current) {
          recaptchaRef.current.reset();
        }
    }
  };

  const onSubmit = async (values) => {
    let token;

    if (recaptchaRef?.current) {
      recaptchaRef.current.reset();
      token = await recaptchaRef.current.executeAsync();
    }

    const endpoint = "vne-forms";
    const payload = {
      forename: values.forename,
      surname: values.surname,
      jobTitle: values.jobTitle, // missing in backend
      companyName: values.companyName,
      email: values.email,
      organisationType: values.organisationType.value,
      organisationSize: values.organisationSize.value,
      country: values.country.label,
      freeText: values.freeText,
    };

    setErrors([]);

    const response = await axiosConfig.post(`/${endpoint}`, payload, {
      headers: {
        "g-recaptcha-response": token,
      },
    });

    await handleLoginResponse(response);
  };

  return (
    <>
      <Formik
        initialValues={{
          forename: profile.forename || "",
          surname: profile.surname || "",
          jobTitle: "",
          companyName: "",
          email: profile.email || "",
          organisationType: [],
          organisationSize: [],
          country: [],
          freeText: "",
        }}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        enableReinitialize
      >
        {({ handleChange, handleSubmit, handleBlur }) => (
          <div className={pageStyles.page}>
            <h1>Connect with us</h1>
            <Messages messages={errors} type={MESSAGE_TYPES.ERROR} />
            <p>
              Please provide us with a few details so that we can put you in
              touch with the right member of our team.
            </p>
            <p>
              <small>
                <sup>*</sup>Indicates required field
              </small>
            </p>
            <Form onSubmit={handleSubmit} id="form-contact">
              <div className={formStyles.formRow}>
                <TextInput
                  label="First name"
                  placeholder="First name*"
                  name="forename"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  readOnly={!!profile.forename}
                />
              </div>
              <div className={formStyles.formRow}>
                <TextInput
                  label="Last name"
                  placeholder="Last name*"
                  name="surname"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  readOnly={!!profile.surname}
                />
              </div>
              <div className={formStyles.formRow}>
                <TextInput
                  label="Job title"
                  placeholder="Job title*"
                  name="jobTitle"
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </div>
              <div className={formStyles.formRow}>
                <TextInput
                  label="Company"
                  placeholder="Company*"
                  name="companyName"
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </div>
              <div className={formStyles.formRow}>
                <TextInput
                  label="Email address (from previous)"
                  placeholder="Email address (from previous)*"
                  name="email"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  readOnly={!!profile.email}
                />
              </div>
              <div className={formStyles.formRow}>
                <SelectDropdown
                  label="organistaion type"
                  placeholder="-Please select organisation type*-"
                  name="organisationType"
                  options={organisationTypeOptions}
                  classNamePrefix="react-select"
                />
              </div>
              <div className={formStyles.formRow}>
                <SelectDropdown
                  label="organistaion size"
                  placeholder="-Please select organisation size*-"
                  name="organisationSize"
                  options={organisationSizeOptions}
                  classNamePrefix="react-select"
                />
              </div>
              <div className={formStyles.formRow}>
                <SelectDropdown
                  label="country/region"
                  placeholder="-Please select Country/Region*-"
                  name="country"
                  options={countryOptions}
                  classNamePrefix="react-select"
                />
              </div>
              <div className={formStyles.formRow}>
                <TextArea
                  label="Please tell us how we can help*"
                  placeholder="Please tell us how we can help*"
                  name="freeText"
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </div>
              <Button type="submit" appearance="primaryBlue" withIcon>
                Send your message
              </Button>
            </Form>
            <ReCAPTCHA
              ref={recaptchaRef}
              size="invisible"
              sitekey={CONFIG.RECAPTCHA_SITE_KEY}
            />
          </div>
        )}
      </Formik>
    </>
  );
}

export default ContactForm;
