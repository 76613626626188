import cn from "classnames";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useEffect, useRef } from "react";
import style from "./ChapterBanner.module.scss";

import ScrollIndicator from "Components/UI/ScrollIndicator/ScrollIndicator";

const ChapterBanner = ({title, copy, color, image}) => {

    gsap.registerPlugin(ScrollTrigger);

    const bannerRef = useRef();
    const bannerColRef = useRef();
    const bannerColInnerRef = useRef();
    const scrollIndicatorRef = useRef();

    useEffect(() => {

        if(window.innerWidth >= 1025) {

            var tlc1 = gsap.timeline({
                scrollTrigger: {
                trigger: bannerRef.current,
                pin: true,
                start: "top top",
                end: "+=1000",
                scrub: true,
                markers: false
                } 
            });
    
            tlc1.to(bannerColRef.current, { transform: "translateY(-70vh)", duration: 1, }, "-=.5");
            tlc1.to(scrollIndicatorRef.current, { opacity: "0", duration: 1, }, "-=1");

        } 
        return () => {
            if(window.innerWidth >= 1025) {
                tlc1.scrollTrigger.kill();
                tlc1.kill();
            }
        };

    }, []);

    var colorClass = "";

    if (color === 'white') {
        colorClass = style.white;
    }

    return (
        <section ref={bannerRef} className={style.chapterBanner}>
            <div className="inner">

                <div className={style.chapterBannerWrapper}>

                    <div ref={bannerColRef} className={cn(style.left, colorClass)}>
                        <div ref={bannerColInnerRef} className={style.leftInner}>
                            <div className={style.chapterTitle}>
                                <h1>{title}</h1>
                            </div>
                            <div className={style.chapterBlock}>
                                <h4>
                                    {copy}
                                </h4>
                            </div>
                        </div>
                    </div>

                    <div className={style.right}>
                        <img src={image} alt="" />
                    </div>

                    <div ref={scrollIndicatorRef} className={style.scrollIndicator}>
                        <ScrollIndicator white />
                    </div>

                </div>

            </div>
        </section>
    )
}

export default ChapterBanner