import UserContext from "Context/UserContext";
import React, { useContext } from "react";
import { Navigate, useLocation } from "react-router-dom";

function PrivateRoute({ children }) {
  const [isLoggedIn] = useContext(UserContext);
  const location = useLocation();
  return isLoggedIn ? (
    children
  ) : (
    <Navigate
      to={{
        pathname: "/login",
        state: { from: location },
      }}
    />
  );
}

export default PrivateRoute;
