import Button from "Components/Forms/Button";
import { TextInput } from "Components/Forms/Fields";
import Messages, { MESSAGE_TYPES } from "Components/Forms/Messages";
import CONFIG from "config";
import { Form, Formik } from "formik";
import { ERROR_MESSAGES } from "Helpers/messages";
import { useRef, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import axiosConfig from "Services/axiosConfig";
import * as Yup from "yup";

function ResetPassword() {
  const [errors, setErrors] = useState([]);
  const [successMessages, setSuccessMessages] = useState([]);
  const recaptchaRef = useRef(null);
  const endpoint = "/identity/users/change-password";
  const validationSchema = Yup.object({
    currentPassword: Yup.string().required("Required"),
    newPassword: Yup.string().required("Required"),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("newPassword"), null], "Passwords must match")
      .oneOf(
        [Yup.ref("currentPassword"), null],
        "Password must be different to your current password"
      ),
  });

  const onSubmit = async (values) => {
    let token;

    if (recaptchaRef?.current) {
      recaptchaRef.current.reset();
      token = await recaptchaRef.current.executeAsync();
    }

    setErrors([]);
    setSuccessMessages([]);

    const payload = {
      currentPassword: values.currentPassword,
      newPassword: values.newPassword,
      confirmNewPassword: values.confirmNewPassword,
    };

    axiosConfig
      .put(`${endpoint}`, payload, {
        headers: {
          "g-recaptcha-response": token,
        },
      })
      .then((res) => {
        let statusCode = res?.data?.statusCode;
        switch (statusCode) {
          case 200:
            setSuccessMessages([res?.data?.successMessage]);
            break;
          default:
            const errorMessages =
              res?.data?.errorMessages ||
              res?.response?.statusText ||
              `${ERROR_MESSAGES.GENERIC_ERROR}`;
            setErrors([errorMessages]);
        }
      });
  };

  return (
    <>

      <h2>Reset password</h2>

      <Messages messages={errors} type={MESSAGE_TYPES.ERROR} />
      <Messages messages={successMessages} type={MESSAGE_TYPES.SUCCESS} />

      <Formik
        initialValues={{
          currentPassword: "",
          newPassword: "",
          confirmNewPassword: "",
        }}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        enableReinitialize
      >
        {({ handleBlur, handleChange, isSubmitting }) => (
          <>
            <Form>
              <TextInput
                type="password"
                label="currentPassword"
                placeholder="Current password"
                name="currentPassword"
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <TextInput
                type="password"
                label="newPassword"
                placeholder="New password"
                name="newPassword"
                onChange={handleChange}
                onBlur={handleBlur}
                helptext="Minimum of 8 characters which must include numbers, letters and
                a symbol"
              />
              <TextInput
                type="password"
                label="Re-enter password"
                placeholder="Re-enter new password"
                name="confirmNewPassword"
                onChange={handleChange}
                onBlur={handleBlur}
                helptext="Re-enter new password to confirm"
              />
              <div>
                <Button loading={isSubmitting} type="submit" appearance="primaryBlue" withIcon>
                  Update my password
                </Button>
              </div>
            </Form>
            <ReCAPTCHA
              ref={recaptchaRef}
              size="invisible"
              sitekey={CONFIG.RECAPTCHA_SITE_KEY}
            />
          </>
        )}
      </Formik>
    </>
  );
}

export default ResetPassword;
