import layout from "Styles/layout.module.scss";
import typ from "Styles/typography.module.scss";
import { Helmet } from "react-helmet";

import ExpiredIcon from "Images/Vectors/brandicon-expired.svg";
import Button from "Components/Forms/Button";

const ResetPasswordExpired = () => {
  return (
    <section>

        <Helmet>
          <title>Link expired</title>
        </Helmet>

        <div className="inner">

            <div className={layout.functionalPage}>

                <img src={ExpiredIcon} alt="" />
                <h1>Link expired</h1>
                <p className={typ.fontSemiBold}>Please click below to resend the password reset email, which is only valid for <span>24 hours</span>.</p>
                <p>Please check your junk folder if you cannot see it in your inbox.</p>

                <Button to="/request-password-reset">
                  Resend password reset email
                </Button>

            </div>

        </div>
    </section>
  )
}

export default ResetPasswordExpired