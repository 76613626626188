export const API_SHORTCODES = {
  DISALLOWED_DOMAIN: "DisallowedDomain",
  USER_EXISTS: "DuplicateUser",
  USER_UNVERIFIED: "UserUnverified",
  EMAIL_UNCONFIRMED: "UserEmailNotConfirmed",
  USER_NOT_FOUND: "UserNotFound",
  ACCOUNT_LOCKED: "AccountLocked",
  INVALID_PASSWORD: "InvalidPassword",
  SAME_PASSWORD: "SamePassword",
  INVALID_TOKEN: "InvalidToken",
}