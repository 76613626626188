import cn from "classnames";
import { gsap } from "gsap";
import { Helmet } from "react-helmet";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useEffect, useState } from "react";
import layout from "../../../Styles/layout.module.scss";
import typ from "../../../Styles/typography.module.scss";

import ChapterBanner from "../../Layout/ChapterBanner/ChapterBanner";
import ChapterTabs from "../../Layout/ChapterTabs/ChapterTabs";
import { chapters } from "../../Layout/ChapterTabs/ChapterTabsConfig";
import CoasterImageContent from "../../Layout/CoasterImageContent/CoasterImageContent";
import BackgroundGradient from "../../UI/BackgroundGradient/BackgroundGradient";
import PercentGraph from "../../UI/PercentGraph/PercentGraph";
import Chapter6Person from "./assets/chapter-banner-6.png";
import PeopleAtDesk from "./assets/people-at-desk.jpg";
import PeopleAtTable from "./assets/people-at-table.jpg";

const Chapter6 = () => {

    gsap.registerPlugin(ScrollTrigger);

    const [chapterState, setChapterState] = useState(chapters);

    useEffect(() => {
        setChapterState(prevState => ({
        ...prevState,
        six: {
            ...prevState.six,
            active: true
        }
        }));
    }, []);

    useEffect(() => {
        setTimeout(() => {
            ScrollTrigger.refresh();
        }, 200)
    }, []);

    return (
        <div>

            <Helmet>
                <title>Building relationships with Gen Z</title>
            </Helmet>

            <BackgroundGradient colorFrom="#1434CB" colorTo="#FFFFFF" gradientStart="top top" gradientDuration="2000">

                <ChapterBanner
                    title="Building relationships with Gen Z"
                    copy="Industry players have a great opportunity to partner to further grow trust among Gen Z, on the cusp of making important financial decisions for their life right now and the future."
                    image={Chapter6Person}
                    color="white"
                />

            </BackgroundGradient>

            <BackgroundGradient colorFrom="#FFFFFF" colorTo="#FCC015" gradientStart="top top" gradientDuration="200">

                <CoasterImageContent img1={PeopleAtDesk} img2={PeopleAtTable}>
                    <h4 className={typ.fontSemiBold}>
                        Conclusions
                    </h4>
                    <p>
                        This audience is still developing relationships with financial institutions. <strong>The time to act is now.</strong>
                    </p>
                    <p>
                        Developing educational content and financial products that align with Gen Z’s short-term and long-term ambitions could strengthen relationships with Gen Z.
                    </p>
                    <p>
                        Priority ambitions centre around investment, planning for retirement, home ownership, entrepreneurial goals (e.g. being self-employed).
                    </p>
                </CoasterImageContent>

                <section>
                    <div className="inner">

                        
                        <div className={layout.titleChunk}>
                            <h3>Association With Trust: Cross-Market Average</h3>
                            <p>Visa drives trust the most, in a prime position for banks and fintech to partner with Visa to further grow trust among Gen Z.</p>
                        </div>

                        <div className={cn(layout.threeCol, layout.threeColGraphs)}>
                            <div>
                                <PercentGraph percent="10" vertical>
                                    <h4>Visa</h4>
                                </PercentGraph>
                            </div>
                            <div>
                                <PercentGraph percent="9" vertical>
                                    <h4>Banks</h4>
                                </PercentGraph>
                            </div>
                            <div>
                                <PercentGraph percent="5" vertical>
                                    <h4>Fintech</h4>
                                </PercentGraph>
                            </div>
                        </div>
                        

                    </div>
                </section>

            </BackgroundGradient>

            <ChapterTabs chapters={chapterState} />
        </div>
        
    )
}

export default Chapter6