import React, { useEffect } from "react";
import pageStyles from "Styles/page.module.scss";
import { Helmet } from "react-helmet";
import axiosConfig from "Services/axiosConfig";
import userState from "Stores/User";
import MarketingPreferences from "./MarketingPreferences";
import ResetPassword from "./ResetPasswordForm";

const Profile = () => {
  const { setProfile } = userState();

  useEffect(() => {
    const fetchProfile = async () => {
        const { data } = await axiosConfig.get(`/my-user-info`);
        const { returnedObject } = data;
        const { profile } = returnedObject;
        if (profile) {
          setProfile(profile);
        }
    }
    fetchProfile();
  }, [setProfile]);

  return (
    <>
      <Helmet>
        <title>Profile</title>
      </Helmet>
      <div className={pageStyles.page}>
        <h1>Manage your profile</h1>

        <div className={pageStyles.profileSection}>
          <MarketingPreferences></MarketingPreferences>
        </div>

        <div className={pageStyles.profileSection}>
          <ResetPassword></ResetPassword>
        </div>
      </div>
    </>
  );
};

export default Profile;
