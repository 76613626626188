import layout from "Styles/layout.module.scss";
import cn from "classnames";
import { Helmet } from "react-helmet";

import IconPW from "Images/Vectors/brandicon-pw.svg";
import RequestPasswordResetForm from "./RequestPasswordResetForm/RequestPasswordResetForm";

const RequestPasswordReset = () => {
  return (
    <section>

        <Helmet>
          <title>Reset password</title>
        </Helmet>

        <div className="inner">

            <div className={cn(layout.functionalPage)}>

                <img src={IconPW} alt="Reset password" />
                <h1>Reset password</h1>
                <p>Please confirm your email address</p>

                <RequestPasswordResetForm />

            </div>

        </div>
    </section>
  )
}

export default RequestPasswordReset