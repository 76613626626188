import { useRef } from "react";
import style from "./Video.module.scss";

import PlayIcon from "Images/Vectors/play-button.svg";

const Video = ({src}) => {

  const vidRef = useRef();
  const vidOverlayRef = useRef();

  var playVideo = () => {
    vidRef.current.play();
    vidRef.current.controls = "controls";
    vidOverlayRef.current.remove();
  }

  return (

    <div className={style.videoWrapper}>
      <div ref={vidOverlayRef} className={style.videoOverlay}>
        <img onClick={playVideo} src={PlayIcon} />
      </div>

      <video ref={vidRef} width="100%" className={style.video}>
        <source src={src} type="video/mp4" />
        Your browser does not support HTML video.
      </video>

    </div>
    
  )  
}

export default Video