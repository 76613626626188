import cn from "classnames";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import layout from "../../../Styles/layout.module.scss";
import typ from "../../../Styles/typography.module.scss";
import style from "./Chapter2.module.scss";

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

import BackgroundGradient from "../../UI/BackgroundGradient/BackgroundGradient";

import ChapterBanner from "../../Layout/ChapterBanner/ChapterBanner";
import Chapter2Person from "./assets/chapter-banner-2.png";

import Priorities from "../../Custom/Priorities/Priorities";

import PercentGraph from "../../UI/PercentGraph/PercentGraph";

import Quote from "../../Layout/Quote/Quote";

import faces12 from "./assets/1-in-2-faces.svg";
import faces710 from "./assets/7-in-10-faces.svg";

import CoasterImageContent from "../../Layout/CoasterImageContent/CoasterImageContent";
import LaptopInBed from "./assets/laptop-in-bed.jpg";
import TwoAtBreakfast from "./assets/two-at-breakfast.jpg";

import ChapterTabs from "../../Layout/ChapterTabs/ChapterTabs";
import { chapters } from "../../Layout/ChapterTabs/ChapterTabsConfig";

const Chapter2 = () => {

    gsap.registerPlugin(ScrollTrigger);

    const [chapterState, setChapterState] = useState(chapters);

    useEffect(() => {
        setChapterState(prevState => ({
        ...prevState,
        two: {
            ...prevState.two,
            active: true
        }
        }));
    }, []);

    useEffect(() => {
        setTimeout(() => {
            ScrollTrigger.refresh();
        }, 200)
    }, []);

    return (
        <div>

            <Helmet>
                <title>The pursuit of financial independence</title>
            </Helmet>

            <BackgroundGradient colorFrom="#1434CB" colorTo="#FFFFFF" gradientStart="top top" gradientDuration="2000">

                <ChapterBanner
                    title="The pursuit of financial independence"
                    copy="They value being healthy above all yet the importance of having enough money illustrates the impact their growing up in tumultuous times on their life priorities."
                    image={Chapter2Person}
                    color="white"
                />

                <section>
                    <div className="inner">

                        <h3 className={typ.title}>Gen Z Life Priorities</h3>
                        <Priorities />
                        
                    </div>
                </section>

                <section>
                    <div className="inner">

                        <div className={layout.twoCol}>

                            <PercentGraph percent="79" onWhite>
                                <h4>say it’s important to <span className={typ.fontBold}>have enough money</span>.</h4>
                                <p>NET very/extremely important.</p>
                            </PercentGraph>

                            <div>
                                <h4 className={typ.fontRegular}>
                                    This is a greater priority than self-care and wellbeing, spending time with family and maintaining work-life balance or starting/ progressing their career.
                                </h4>
                            </div>

                        </div>

                    </div>
                </section>

                <Quote 
                quote="I think of saving money almost every day. I have got many projects going in order to achieve my goals; I have to start immediately."
                source="IT, 18"
                color="blue" />

            </BackgroundGradient>

            <BackgroundGradient colorFrom="#FFFFFF" colorTo="#FCC015" gradientStart="top top" gradientDuration="1000">

                <section>
                    <div className="inner">

                        <div className={cn(layout.twoCol, style.infographicBlockWrapper)}>

                            <div>
                                <h4 className={typ.fontRegular}>
                                    Their professional aspirations and pro-active mindset to planning their retirement reflect their desire to emancipate from traditional ways of financial independence.
                                </h4>
                            </div>

                            <PercentGraph percent="48" onWhite>
                                <h4>of Gen Z are proactively planning for retirement.</h4>
                                <p>NET very/critically important.</p>
                            </PercentGraph>

                        </div>

                        <div className={cn(layout.twoCol, style.infographicBlockWrapper)}>

                            <div className={style.infographicBlock}>
                                <div>
                                    <img src={faces710} alt="" />
                                </div>
                                <div>
                                    <h4>7 in 10</h4>
                                    <h5>find it appealing to work in a job with flexible working hours.</h5>
                                </div>
                            </div>

                            <div className={style.infographicBlock}>
                                <div>
                                    <img src={faces12} alt="" />
                                </div>
                                <div>
                                    <h4>1 in 2</h4>
                                    <h5>find it appealing to start their own business.</h5>
                                </div>
                            </div>

                        </div>

                    </div>
                </section>

                <CoasterImageContent img1={TwoAtBreakfast} img2={LaptopInBed}>
                    <PercentGraph percent="4">
                        <h4>of Gen Z are already self-employed.</h4>
                        <p>Gen Z Employment Situation.</p>
                    </PercentGraph>
                </CoasterImageContent>

            </BackgroundGradient>

            <ChapterTabs chapters={chapterState} />
        </div>
        
    )
}

export default Chapter2