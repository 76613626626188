import Cookies from "js-cookie";
import axiosConfig from "./axiosConfig";

export const isAuthenticated = () => {
  const token = getToken();
  let session;

  try {
    if (token) {
      const base64Url = token.split(".")[1];
      const base64 = base64Url?.replace("-", "+").replace("_", "/");
      if (!base64) return;
      // what is window.atob ?
      // https://developer.mozilla.org/en-US/docs/Web/API/WindowOrWorkerGlobalScope/atob
      session = JSON.parse(window.atob(base64));
    }
  } catch (error) {
    console.log(error);
  }
  return session;
};

const convertTimestamp = (s) => {
  var date = new Date(s * 1000);
  return date;
};

export const getToken = () => {
  return Cookies.get("__token");
};

export const setToken = (token, expiry) => {
  let expiryDate = new Date(convertTimestamp(expiry));
  Cookies.set("__token", token, {
    expires: expiryDate,
    sameSite: "Strict",
    secure: true,
  });
};

export const removeToken = () => {
  return Cookies.remove("__token");
};

export const getRefreshToken = () => {
  return Cookies.get("__refresh-token");
};

export const setRefreshToken = (token, expiry) => {
  Cookies.set("__refresh-token", token, { sameSite: "Strict", secure: true });
};

export const removeRefreshToken = () => {
  return Cookies.remove("__refresh-token");
};

export const refreshToken = () => {
  return axiosConfig.post(`/identity/auth/refresh`, {
    token: getRefreshToken(),
  });
};

export const logout = () => {
  return axiosConfig.post(`/identity/auth/logout`);
};

export const removeData = () => {
  removeToken();
  removeRefreshToken();
};
