import { Helmet } from "react-helmet";

import layout from "../../../../Styles/layout.module.scss";
import typ from "../../../../Styles/typography.module.scss";

import ThumbsUp from "Images/Vectors/brandicon-thankyou.svg";

const CARAConfirmationMessage = () => {
  return (
    <section>

        <Helmet>
          <title>Thank you</title>
        </Helmet>

        <div className="inner">

            <div className={layout.functionalPage}>

                <img src={ThumbsUp} alt="" />
                <h1>Thank you</h1>
                <p className={typ.fontSemiBold}>A member of our team will review your request and get back to you ASAP.</p>
                <p>For assistance, please contact <a href="mailto:EUResearchandInsights@visa.com">EUResearchandInsights@visa.com</a></p>
                
            </div>

        </div>
    </section>
  )
}

export default CARAConfirmationMessage