import { ROUTES } from "App";
import Button from "Components/Forms/Button";
import { Checkbox, TextInput } from "Components/Forms/Fields";
import Messages, { MESSAGE_TYPES } from "Components/Forms/Messages";
import CONFIG from "config";
import { API_SHORTCODES } from "Constants";
import { Form, Formik } from "formik";
import { ERROR_MESSAGES } from "Helpers/messages";
import { useRef, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { useNavigate } from "react-router-dom";
import axiosConfig from "Services/axiosConfig";
import layout from "Styles/layout.module.scss";
import * as Yup from "yup";

const CARAForm = () => {
    const validationSchema = Yup.object({
        firstName: Yup.string().required("Required"),
        lastName: Yup.string().required("Required"),
        email: Yup.string().required("Required"),
        jobTitle: Yup.string().required("Required"),
        companyName: Yup.string().required("Required"),
        address: Yup.string().required("Required"),
        phoneNumber: Yup.string().required("Required"),
        businessSector: Yup.string().required("Required"),
    });
    const [errors, setErrors] = useState([]);
    let navigate = useNavigate();
    const recaptchaRef = useRef(null);

    const handleCaraResponse = (res) => {
        navigate(ROUTES.REQUEST_ACCESS_CONFIRMATION);
    };

    const handleErrorResponse = (error) => {
        let errors;
        const errorMessages =
          error.response?.data?.errorMessages ||
          error.response?.response?.statusText ||
          `${ERROR_MESSAGES.GENERIC_ERROR}`;
        if (errorMessages?.includes(API_SHORTCODES.DISALLOWED_DOMAIN)) {
          errors = [
            <>
              We are unable to give you access. If you have used a personal email
              address, please use a business email address to create your account.
              Please contact{" "}
              <a href="mailto:EUResearchandInsights@visa.com">EUResearchandInsights@visa.com</a> for
              assistance.
            </>,
          ];
        } else if (errorMessages?.includes(API_SHORTCODES.USER_EXISTS)) {
          errors = [
            <>
              This email address is already registered for Visa Insights. Please sign in or request a password reset via the Sign
              In link below. Please contact{" "}
              <a href="mailto:EUResearchandInsights@visa.com">EUResearchandInsights@visa.com</a> for further
              assistance.
            </>,
          ];
        } else {
          errors = errorMessages;
        }
        setErrors(errors);
        if (recaptchaRef?.current) {
          recaptchaRef.current.reset();
        }
      };
    
    
    const onCaraFormSubmit = async (values) => {
        let user;
        let endpoint;
        let token;
    
        if (recaptchaRef?.current) {
            recaptchaRef.current.reset();
            token = await recaptchaRef.current.executeAsync();
        }
    
        endpoint = "identity/users/full-user";
        user = {
            email: values.email,
            surname: values.lastName,
            forename: values.firstName,
            jobTitle: values.jobTitle,
            companyName: values.companyName,
            address: values.address,
            phoneNumber: values.phoneNumber,
            businessSector: values.businessSector,
            acceptedFutureCommunications: values.acceptedFutureCommunications,
        };
    
        setErrors([]);
    
        return axiosConfig
            .post(`/${endpoint}`, user, {
                headers: {
                    "g-recaptcha-response": token,
                },
            })
            .then(handleCaraResponse)
            .catch(handleErrorResponse)
    };

    return (
        <div>

            <Formik
                initialValues={{
                    firstName: "",
                    lastName: "",
                    email: "",
                    jobTitle: "",
                    companyName: "",
                    address: "",
                    phoneNumber: "",
                    businessSector: "",
                }}
                validationSchema={validationSchema}
                onSubmit={onCaraFormSubmit}
                enableReinitialize
            >
                {({ handleBlur, handleChange, values, isSubmitting }) => (
                <>
                    <Form>
                        <Messages messages={errors} type={MESSAGE_TYPES.ERROR} />
                        <div className={layout.formCols}>
                            
                            <div>
                                <TextInput
                                    type="text"
                                    label="firstName"
                                    placeholder="First name"
                                    name="firstName"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                />
                                <TextInput
                                    type="text"
                                    label="lastName"
                                    placeholder="Last name"
                                    name="lastName"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                />
                                <TextInput
                                    type="email"
                                    label="email"
                                    placeholder="Email address"
                                    name="email"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                />
                                <TextInput
                                    type="text"
                                    label="jobTitle"
                                    placeholder="Job title"
                                    name="jobTitle"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                />
                            </div>

                            <div>
                                <TextInput
                                    type="text"
                                    label="companyName"
                                    placeholder="Business name"
                                    name="companyName"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                />
                                <TextInput
                                    type="text"
                                    label="address"
                                    placeholder="Business address"
                                    name="address"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                />
                                <TextInput
                                    type="number"
                                    label="phoneNumber"
                                    placeholder="Phone number"
                                    name="phoneNumber"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                />
                                <TextInput
                                    type="text"
                                    label="businessSector"
                                    placeholder="Industry/sector"
                                    name="businessSector"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                />
                            </div>

                        </div>
                    
                    
                    <Checkbox
                        id="acceptedFutureCommunications"
                        name="acceptedFutureCommunications"
                        value={values.acceptedFutureCommunications}
                        checked={values.acceptedFutureCommunications}
                        onChange={handleChange}
                    >
                        By checking this box, you agree to receive marketing communications about Visa business products and services. You can unsubscribe at any time. For more information on how Visa collects, uses, and protects your personal information, please review our{" "}
                        <a
                        href="https://usa.visa.com/legal/privacy-policy.html"
                        target="_blank"
                        rel="noopener noreferrer"
                        >
                        Global Privacy Notice
                        </a>.
                    </Checkbox>
                    <div>
                        <Button loading={isSubmitting} type="submit" appearance="primaryBlue" withIcon>
                            Request access
                        </Button>
                    </div>
                    </Form>
                    <ReCAPTCHA
                    ref={recaptchaRef}
                    size="invisible"
                    sitekey={CONFIG.RECAPTCHA_SITE_KEY}
                    />
                </>
                )}
            </Formik>

        </div>
    )
}

export default CARAForm