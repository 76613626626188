import axiosConfig from "Services/axiosConfig";

export const Login = async (username, password, token) => {
  try {
    const payload = { username, password };
    const headers = {
        "g-recaptcha-response": token,
    };
    console.log(headers);
    await axiosConfig.post(`/identity/auth/token`, payload, { headers });
    const res = await axiosConfig.get(`/my-user-info`);
    return res.data;
  } catch (e) {
    if(e.response?.data?.errorMessages) {
      throw new Error(JSON.stringify({
        errorMessages: e.response?.data?.errorMessages,
      }));
    } else {
      throw new Error(JSON.stringify({
        errorMessages: ['Sorry, there was a problem logging in.'],
      }))
    }
  }
};
