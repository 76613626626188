import { useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import ChapterBanner from "../../Layout/ChapterBanner/ChapterBanner";
import ChapterTabs from "../../Layout/ChapterTabs/ChapterTabs";
import { chapters } from "../../Layout/ChapterTabs/ChapterTabsConfig";
import BackgroundGradient from "../../UI/BackgroundGradient/BackgroundGradient";
import DesktopBanner from "./assets/LARGE_1080p_Methodology-Group.png";
import styles from "./styles.module.scss";

import FlagGERMANY from "Images/Vectors/flag-GERMANY.svg";
import FlagITALY from "Images/Vectors/flag-ITALY.svg";
import FlagPOLAND from "Images/Vectors/flag-POLAND.svg";
import FlagUK from "Images/Vectors/flag-UK.svg";
import CalendarIcon from "Images/Vectors/icon-calendar-blue.svg";
import StopwatchIcon from "Images/Vectors/icon-stopwatch-blue.svg";

const SampleMethodology = () => {
  gsap.registerPlugin(ScrollTrigger);

  const [chapterState, setChapterState] = useState(chapters);

  useEffect(() => {
    setChapterState((prevState) => ({
      ...prevState,
    }));
  }, []);

  const videoSectionRef = useRef();
  const videoOneRef = useRef();
  const videoTwoRef = useRef();
  const videoWrapperRef = useRef();

  useEffect(() => {
    var c1vs = gsap.timeline({
      scrollTrigger: {
        trigger: videoSectionRef.current,
        start: "top 70%",
        end: "+=500",
        scrub: 1,
      },
    });

    c1vs.to(
      videoWrapperRef.current,
      { transform: "translateY(0px)", duration: 1 },
      "0"
    );
    c1vs.to(
      videoOneRef.current,
      { transform: "translateY(0px)", duration: 1 },
      "0"
    );
    c1vs.to(
      videoTwoRef.current,
      { transform: "translateY(0px)", duration: 1 },
      "0"
    );

    return () => {
      c1vs.scrollTrigger.kill();
      c1vs.kill();
    };
  }, []);

  useEffect(() => {
    setTimeout(() => {
      ScrollTrigger.refresh();
    }, 200);
  }, []);

  return (
    <div>

      <Helmet>
        <title>Sample & Methodology</title>
      </Helmet>

      <BackgroundGradient
        colorFrom="#1434CB"
        colorTo="#FFFFFF"
        gradientStart="top top"
        gradientDuration="1500"
      >
        <ChapterBanner
          title={
            <>
              <h1>Sample & Methodology</h1>
              <h4>Research commissioned by Visa, carried out by Material</h4>
            </>
          }
          image={DesktopBanner}
          color="white"
        />

        <div className={styles.wrapper}>
          <section className={styles.qualitativeSection}>
            <h2>Qualitative</h2>
            <div className={styles.threeCol}>
              <div className={styles.flagCol}>
                <img src={FlagUK} alt="" />
                <span>UK</span>
              </div>
              <div className={styles.flagCol}>
                <img src={FlagGERMANY} alt="" />
                <span>Germany</span>
              </div>
              <div className={styles.flagCol}>
                <img src={FlagITALY} alt="" />
                <span>Italy</span>
              </div>
            </div>
            
            <div className={styles.threeColWide}>
              <div className={styles.statCol}>
                <div className={styles.statColTitle}>3 day</div>
                <span>digital ethnographic immersion</span>
                <ul>
                    <li><span>→</span> N=12 per market</li>
                    <li><span>→</span>All recruits Gen Z (18–25)</li>
                </ul>
              </div>
              <div className={styles.statCol}>
                <div className={styles.statColTitle}>
                    <img src={CalendarIcon} alt="" />
                </div>
                <ul>
                    <li>Fieldwork dates</li>
                </ul>
                <dl>
                    <dt>Ethnographic immersions</dt>
                    <dd>22.09.2021 – 28.10.2021</dd>
                    <dt>Online groups</dt>
                    <dd>05.10.2021 – 12.10.2021</dd>
                </dl>
              </div>
              <div className={styles.statCol}>
                <div className={styles.statColTitle}>9x</div>
                <span>digital focus groups (3 per market), split by Cohort</span>
                <ul>
                    <li><span>+</span> Cohort A: 4 x 18–21 year olds</li>
                    <li><span>+</span> Cohort B: 4 x 22–23 year olds</li>
                    <li><span>+</span> Cohort C: 4 x 24–25 year olds</li>
                </ul>
              </div>
            </div>
          </section>

          <section className={styles.quantativeSection}>
            <h2>Quantative</h2>
            <div className={styles.fourCol}>
              <div className={styles.flagCol}>
                <img src={FlagUK} alt="" />
                <span>UK</span>
              </div>
              <div className={styles.flagCol}>
                <img src={FlagGERMANY} alt="" />
                <span>Germany</span>
              </div>
              <div className={styles.flagCol}>
                <img src={FlagITALY} alt="" />
                <span>Italy</span>
              </div>
              <div className={styles.flagCol}>
                <img src={FlagPOLAND} alt="" />
                <span>Poland</span>
              </div>
            </div>
            
            <div className={styles.threeColWide}>
              <div className={styles.statCol}>
                <div className={styles.statColTitle}>4003</div>
                <span>participants</span>
                <ul>
                    <li><span>→</span> 3,200 Gen Z (18–24)</li>
                    <li><span>→</span> 803 Millennials (25–40)</li>
                </ul>
              </div>
              <div className={styles.statCol}>
                <div className={styles.statColTitle}>
                    <img src={CalendarIcon} alt="" />
                </div>
                <ul>
                    <li>Fieldwork dates</li>
                </ul>
                <dl>
                    <dd>30.09.2021 – 14.10.2021</dd>
                </dl>
              </div>
              <div className={styles.statCol}>
                <div className={styles.statColTitle}><img src={StopwatchIcon} alt="" /></div>
                <span>15 minute online survey</span>
              </div>
            </div>
          </section>
        </div>
      </BackgroundGradient>

      <ChapterTabs chapters={chapterState} />
    </div>
  );
};

export default SampleMethodology;
