import { Helmet } from "react-helmet";
import layout from "Styles/layout.module.scss";
import typ from "Styles/typography.module.scss";

import ThumbsUp from "Images/Vectors/brandicon-thankyou.svg";

const RPRConfirmationMessage = () => {
  return (
    <section>

        <Helmet>
          <title>Thank you</title>
        </Helmet>

        <div className="inner">

            <div className={layout.functionalPage}>

                <img src={ThumbsUp} alt="" />
                <h1>Thank you</h1>
                <p className={typ.fontSemiBold}>We have sent you an email with a link to activate your Visa Insights account, which is only valid for <span>24 hours</span>.</p>
                <p>Please check your junk mail folder if this is not in your inbox, or contact <a href="mailto:EUResearchandInsights@visa.com">EUResearchandInsights@visa.com</a> for assistance. </p>

            </div>

        </div>
    </section>
  )
}

export default RPRConfirmationMessage