import style from "./Quote.module.scss";
import cn from "classnames";

import QuoteOpen from "./assets/quote-open-blue.svg";
import QuoteClose from "./assets/quote-close-gold.svg";
import QuoteCloseWhite from "./assets/quote-close-white.svg";

const Quote = ({
  quote, 
  source,
  color, 
  bgColor,
  closeWhite 
}) => {
  return (
    <section className={cn(style.quote, style["bg"+bgColor])}>
        <div className="inner">

            <img src={QuoteOpen} alt="Quotation mark" />
            <h5 className={cn(style[color])}>{quote}</h5>
            <h5 className={style.quoteSource}>{source}</h5>
            {closeWhite ? (
              <img src={QuoteCloseWhite} alt="Quotation mark" />
            ) : (
              <img src={QuoteClose} alt="Quotation mark" />
            )}
            

        </div>
    </section>
  )
}

export default Quote