export const MenuItemTypes = {
  LINK: "link",
  SUBNAV: "subnav",
  LOGOUT: "logout",
};

export const MenuLevels = {
  TOP: "top",
  ACCOUNT: "account",
};

export const MenuConfig = {
  [MenuLevels.TOP]: {
    subtitle: null,
    topBorder: true,
    border: false,
    groups: [
      {
        title: "Content",
        subtitle: null,
        topBorder: true,
        border: false,
        items: [
          {
            title: "Gen Z: The product of their time",
            type: MenuItemTypes.LINK,
            path: "/chapter-1",
            border: true,
            number: "1"
          },
          {
            title: "The pursuit of financial independence",
            type: MenuItemTypes.LINK,
            path: "/chapter-2",
            border: true,
            number: "2"
          },
          {
            title: "Eyes firmly on the future...",
            type: MenuItemTypes.LINK,
            path: "/chapter-3",
            border: true,
            number: "3"
          },
          {
            title: "... with tools to budget day to day",
            type: MenuItemTypes.LINK,
            path: "/chapter-4",
            border: true,
            number: "4"
          },
          {
            title: "Buy Now Pay Later - The New Version of credit?",
            type: MenuItemTypes.LINK,
            path: "/chapter-5",
            border: true,
            number: "5"
          },
          {
            title: "Building relationships with Gen Z",
            type: MenuItemTypes.LINK,
            path: "/chapter-6",
            border: true,
            number: "6"
          },
          {
            title: "Sample and methodology",
            type: MenuItemTypes.LINK,
            path: "/sample-methodology",
            border: true,
          },
        ],
      },
      {
        title: "Your account",
        subtitle: null,
        topBorder: true,
        border: false,
        items: [
          {
            title: "Manage your account",
            type: MenuItemTypes.LINK,
            path: "/profile",
            border: true,
          },
          {
            title: "Logout",
            type: MenuItemTypes.LOGOUT,
            path: "/login",
            border: true,
          },
        ],
      },
    ],
  },
  [MenuLevels.ACCOUNT]: {
    subtitle: null,
    topBorder: true,
    border: false,
    back: MenuLevels.TOP,
    groups: [
      {
        title: "Your Account",
        subtitle: null,
        topBorder: true,
        border: false,
        items: [
          {
            title: "Manage your account",
            type: MenuItemTypes.LINK,
            border: true,
            path: "/profile",
          },
          {
            title: "Logout ",
            type: MenuItemTypes.LOGOUT,
            border: true,
          },
        ],
      },
    ],
  },
};
