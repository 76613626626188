import axios from "axios";
import { getCookie, writeCookie } from "Helpers/cookies";
import { useNavigate } from "react-router-dom";
import {
  getToken, refreshToken,
  removeData, setRefreshToken, setToken
} from "Services/auth";
import CONFIG from "../config";

const instance = axios.create({
  baseURL: CONFIG.API_URL,
  withCredentials: true,
  xsrfCookieName: "xsrf-token",
  xsrfHeaderName: "x-csrf-token",
  isRetryAttempt: false,
});

instance.interceptors.request.use(async (req) => {
  if (window.location.pathname === "/login") {
    await axios.get(`${CONFIG.API_URL}/alive`);
  }
  const xcsrfToken = getCookie("xsrfToken");
  if (xcsrfToken) {
    req.headers["x-csrf-token"] = xcsrfToken;
  }
  const token = getToken();
  if (token) {
    req.headers["Authorization"] = `Bearer ${token}`;
  }

  return req;
});

instance.interceptors.response.use(
  async (res) => {
    if (res?.data?.token) {
      setToken(res.data.token, res.data.refreshTokenExpiration);
    }
    if (res?.data?.refreshToken) {
      setRefreshToken(res.data.refreshToken);
    }
    if (res.headers["xsrf-token"]) {
      writeCookie("xsrfToken", res.headers["xsrf-token"]);
    }
    return res;
  },
  async (error) => {
    const { config: originalReq } = error;

    // Skip login request as we'll handle this in the form.
    if(window.location.pathname === "/login") { 
      throw error;
    // skip refresh token request and retry attempt to avoid infinite loops.
    } else if (
      originalReq.url !== "/identity/auth/refresh" &&
      originalReq.isRetryAttempt === false &&
      error.request.status === 401
    ) {
      try {
        await refreshToken();

        originalReq.isRetryAttempt = true;
        return await instance.request(originalReq);
      } catch (e) {
        // log the user out and remove data if we fail to refresh the token (due to expired or missing token)
        // or persistent 401 errors from original requests
        if (e.response && e.response.status === 401) {
          originalReq.isRetryAttempt = false;
          await axios.get(`${CONFIG.API_URL}/identity/auth/logout`);
          removeData();
          const navigate = useNavigate();
          navigate("/login");
        }
        throw e;
      }
    } else {
      throw error;
    }
  }
);

export default instance;
