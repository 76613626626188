import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

import Button from "Components/Forms/Button";
import { VisaLogo, VisaLogoColours } from "Components/UI/VisaLogo";
import { ReactComponent as IconChevronBack } from "Images/Vectors/chevron-back-menu.svg";
import { ReactComponent as IconChevronLogout } from "Images/Vectors/chevron-logout.svg";
import { ReactComponent as IconChevronRight } from "Images/Vectors/chevron-right.svg";
import { ReactComponent as MenuActive } from "Images/Vectors/hamburger-active.svg";
import { ReactComponent as MenuInactive } from "Images/Vectors/hamburger-inactive.svg";


import typographyStyles from "Styles/typography.module.scss";
import styles from "./Menu.module.scss";

import {
  MenuConfig,
  MenuItemTypes,
  MenuLevels
} from "./Menu.config";

import UserContext from "Context/UserContext";
import { isDesktop } from "Helpers/device";
import { removeData } from "Services/auth";
import axiosConfig from "Services/axiosConfig";

const SubNavItem = ({ children, onClick, border }) => (
  <div
    onClick={onClick}
    className={`${styles.menuItemLink} ${border ? styles.hasBorder : ""}`}
  >
    {children}
    <IconChevronRight aria-hidden />
  </div>
);

const MenuItem = ({ children, onClick, to = "/", border }) => (
  <HashLink to={to} onClick={onClick} className={`${styles.menuItemLink} ${border ? styles.hasBorder : ""}`}>
    {children}
    <IconChevronRight aria-hidden />
  </HashLink>
);

const LogoutItem = ({ children, onClick, to = "/", border }) => (
  <HashLink onClick={onClick} to={to} className={`${styles.menuItemLink} ${border ? styles.hasBorder : ""}`}>
    {children}
    <IconChevronLogout aria-hidden />
  </HashLink>
);

export const Menu = ({ children, showLogo = true, logoColor = VisaLogoColours.WHITE }) => {
  let navigate = useNavigate();
  const config = MenuConfig;
  const [isLoggedIn, setIsLoggedIn] = useContext(UserContext);
  const [active, setActive] = useState(false);
  const [currentLevel, setCurrentLevel] = useState(MenuLevels.TOP);
  const { groups, back } = config[currentLevel];

  const logout = () => {
    axiosConfig.post(`/identity/auth/logout`).then((response) => {
      let statusCode = response.status;
      switch (statusCode) {
        case 200:
          setIsLoggedIn(false);
          removeData();
          break;
        default:
          console.error(response.data?.errorMessages);
      }
    });
  };

  const renderItem = ({ title, type, border, path, number }) => {
    switch (type) {
      case MenuItemTypes.LOGOUT:
        return (
          <LogoutItem
            key={title}
            onClick={async () => {
              await logout();
              setActive(false);
            }}
            border={border}
          >
            {title}
          </LogoutItem>
        );
      case MenuItemTypes.LINK:
        return (
          <MenuItem
            key={title}
            onClick={() => {
              setActive(false);
            }}
            border={border}
            to={path}
          >
            {number ? (
              <span>{number}</span>
            ) : null}
            {title}
          </MenuItem>
        );
      case MenuItemTypes.SUBNAV:
        return (
          <SubNavItem
            key={title}
            border={border}
            onClick={() => setCurrentLevel(path)}
          >
            {title}
          </SubNavItem>
        );
      default:
        return;
    }
  };

  const renderGroup = ({ title, subtitle, border, topBorder, items, path }) => {
    return (
      <div className={styles.menuGroup} key={title}>
        {title && <div className={`${typographyStyles.bodyCopy1} ${styles.menuTitle}`}>{title}</div>}
        {subtitle && !path && <div className={`${typographyStyles.bodyCopy1} ${styles.menuSubtitle}`}>{subtitle}</div>}
        {subtitle && path && (
          <MenuItem
            topBorder={true}
            border={false}
            to={path}
            onClick={() => {
              setActive(false);
            }}
          >
            {subtitle}
          </MenuItem>
        )}
        {topBorder && <hr className="hr--top" />}
        {items.map(renderItem)}
        {border && <hr />}
      </div>
    );
  };

  const toHomepage = () => {
    setActive(false);
    navigate("/");
  };

  return !!isLoggedIn ? (
    <div className={styles.menuHarness}>
      {showLogo && (
        <>
        <div className={styles.visaLogoWrapper} to="/" onClick={() => setActive(false)}>
          <VisaLogo logo={logoColor}></VisaLogo>
        </div>
        </>
      )}
      {active ? (
        <MenuActive className={styles.closeButton} onClick={() => setActive(false)} />
      ) : (
        <>
          <div className={styles.openButtonWrapper}>
            <MenuInactive className={styles.openButton} onClick={() => setActive(true)} />
          </div>
        </>
      )}
      <div className={`${styles.menuWrapper} ${active ? styles.active : styles.inactive }`}>
        {!back && (
          <button className={styles.backButton} to="/" onClick={() => toHomepage()}>
            <IconChevronBack /> Home page
          </button>
        )}
        {!isDesktop && (
          <>
            <div className={styles.visaLogoWrapper} to="/" onClick={() => setActive(false)}>
              <VisaLogo logo={VisaLogoColours.WHITE}></VisaLogo>
            </div>
          </>
        )}
        {back && (
          <button className={styles.backButton} onClick={() => setCurrentLevel(back)}>
            <IconChevronBack /> Back
          </button>
        )}
        {groups?.map(renderGroup)}
        {currentLevel === MenuLevels.TOP && (
          <Button
            onClick={() => {
              setActive(false);
            }}
            gold
            withIcon
            to="/contact"
          >
            Connect with us
          </Button>
        )}
      </div>
      <div onClick={() => setActive(false)}>{children}</div>
    </div>
  ) : (
    <>{children}</>
  );
};
