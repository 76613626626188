import cn from "classnames";
import { useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import layout from "../../../Styles/layout.module.scss";
import typ from "../../../Styles/typography.module.scss";
import style from "./Chapter3.module.scss";

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

import BackgroundGradient from "../../UI/BackgroundGradient/BackgroundGradient";

import ChapterBanner from "../../Layout/ChapterBanner/ChapterBanner";
import Chapter3Person from "./assets/chapter-banner-3.png";

import PercentGraph from "../../UI/PercentGraph/PercentGraph";

import Quote from "../../Layout/Quote/Quote";

import CoasterImageContent from "../../Layout/CoasterImageContent/CoasterImageContent";
import PeopleWalking from "./assets/people-walking.jpg";
import PersonSitting from "./assets/person-sitting.jpg";

import RankingList from "../../Custom/RankingList/RankingList";

import Video from "Components/UI/Video/Video";
import Video1 from "Videos/Ch3-UK24-ISAs.mp4";
import Video2 from "Videos/Ch3-UK23-Crypto.mp4";
import Video3 from "Videos/Ch3-UK21-income.mp4";

import ChapterTabs from "../../Layout/ChapterTabs/ChapterTabs";
import { chapters } from "../../Layout/ChapterTabs/ChapterTabsConfig";

const Chapter3 = () => {

    gsap.registerPlugin(ScrollTrigger);

    const [chapterState, setChapterState] = useState(chapters);

    useEffect(() => {
        setChapterState(prevState => ({
        ...prevState,
        three: {
            ...prevState.three,
            active: true
        }
        }));
    }, []);

    const videoHalfRef = useRef();
    const videoTextRef = useRef();

    useEffect(() => {
        var c3vs = gsap.timeline({
            scrollTrigger: {
            trigger: videoHalfRef.current,
            start: "top 70%",
            end: "+=400",
            scrub: 1
            } 
        });

        c3vs.to(videoTextRef.current, { transform: "translateY(0px)", duration: 1, }, "0");
        c3vs.to(videoTextRef.current, { opacity: "1", duration: 1, }, "0");

        return () => {
            c3vs.scrollTrigger.kill();
            c3vs.kill();
        };
    }, []);

    useEffect(() => {
        setTimeout(() => {
            ScrollTrigger.refresh();
        }, 200)
    }, []);

    return (
        <div>

            <Helmet>
                <title>Eyes firmly on their future...</title>
            </Helmet>

            <BackgroundGradient colorFrom="#1434CB" colorTo="#FFFFFF" gradientStart="top top" gradientDuration="2000">

                <ChapterBanner
                    title="Eyes firmly on their future..."
                    copy="The diversification of their investment strategies is a sign of their efforts to ensure their future."
                    image={Chapter3Person}
                    color="white"
                />

                <Quote 
                quote="I try to invest rather than just putting money in the bank. I’ve invested in some shares, and have looked at investing in property as well."
                source="DE, 23"
                color="blue" />

                <CoasterImageContent img1={PeopleWalking} img2={PersonSitting}>
                    <PercentGraph percent="60" onWhite>
                        <h4>already invest.</h4>
                    </PercentGraph>
                </CoasterImageContent>

            </BackgroundGradient>

            <BackgroundGradient colorFrom="#FFFFFF" colorTo="#FCC015" gradientStart="top top" gradientDuration="100">

                <section>
                    <div className="inner">

                        <h3 className={typ.title}>Ranking of investment products owners</h3>
                        <RankingList />
                    </div>
                    
                </section>

                <Quote 
                quote="I have been investing in crypto quite a lot lately, so I like to keep informed about this in order to have some certainty about my future."
                source="DE, 20"
                color="blue" />

                <section>
                    <div className="inner">

                        <div className={style.videoSection}>

                            <div className={style.left}>
                                <Video src={Video1} />
                                <div className={cn(style.textBox, layout.videoText)}>
                                    <h4>UK, 24</h4>
                                    <p>This Gen Zer talks through his financial plan, investment strategies and mixing high/low revenue generating investment products, such as ISAs.</p>
                                </div>
                            </div>
                            
                            <div className={style.right}>
                                <Video src={Video2} />
                                <div className={cn(style.textBox, layout.videoText)}>
                                    <h4>UK, 24</h4>
                                    <p>This Gen Zer talks through his financial plan, investment strategies and mixing high/low revenue generating investment products, such as ISAs.</p>
                                </div>
                            </div>

                        </div>

                    </div>
                </section>

                <Quote 
                quote="I see rare investment items as a good way to spend my money, like collecting things and when they go up in value, I can sell them for more than what I bought them for."
                source="UK, 18"
                color="blue"
                closeWhite={true} />

                <section ref={videoHalfRef}>
                    <div className="inner">

                        <div className={layout.twoCol}>

                            <div className={style.videoBlock}>
                                <Video src={Video3} />
                                <h5>UK, 21</h5>
                            </div>
                            <div className={style.videoText} ref={videoTextRef}>
                                <h4 className={typ.fontRegular}>
                                    Their strive for financial independence and acknowledgment of the financial challenges and struggles ahead have led them to generating income through non-traditional means at an early age, before going into full-time employment.
                                </h4>
                            </div>

                        </div>

                    </div>
                </section>

            </BackgroundGradient>

            <ChapterTabs chapters={chapterState} />
        </div>
        
    )
}

export default Chapter3