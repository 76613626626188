import style from "./RankingList.module.scss";

const RankingList = () => {
  return (
    <div className={style.rankingList}>

        <div className={style.rankingItem}>
            <div className={style.label}>Cryptocurrency</div>
            <div className={style.bar}>
                <div style={{width: "100%"}}></div>
            </div>
            <div className={style.value}>22%</div>
        </div>

        <div className={style.rankingItem}>
            <div className={style.label}>Shares</div>
            <div className={style.bar}>
                <div style={{width: "95%"}}></div>
            </div>
            <div className={style.value}>21%</div>
        </div>

        <div className={style.rankingItem}>
            <div className={style.label}>ISA</div>
            <div className={style.bar}>
                <div style={{width: "77%"}}></div>
            </div>
            <div className={style.value}>17%</div>
        </div>

        <div className={style.rankingItem}>
            <div className={style.label}>Property</div>
            <div className={style.bar}>
                <div style={{width: "63%"}}></div>
            </div>
            <div className={style.value}>14%</div>
        </div>

        <div className={style.rankingItem}>
            <div className={style.label}>Premium Bonds</div>
            <div className={style.bar}>
                <div style={{width: "45%"}}></div>
            </div>
            <div className={style.value}>10%</div>
        </div>

        <div className={style.rankingItem}>
            <div className={style.label}>Precious Metals</div>
            <div className={style.bar}>
                <div style={{width: "36%"}}></div>
            </div>
            <div className={style.value}>8%</div>
        </div>

        <div className={style.rankingItem}>
            <div className={style.label}>FX Trading</div>
            <div className={style.bar}>
                <div style={{width: "31%"}}></div>
            </div>
            <div className={style.value}>7%</div>
        </div>

        <div className={style.rankingItem}>
            <div className={style.label}>Other</div>
            <div className={style.bar}>
                <div style={{width: "4%"}}></div>
            </div>
            <div className={style.value}>1%</div>
        </div>

    </div>
  )
}

export default RankingList