import React, { useState, useRef } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import axiosConfig from "Services/axiosConfig";
import userState from "Stores/User";
import ReCAPTCHA from "react-google-recaptcha";
import CONFIG from "config";
import Messages, { MESSAGE_TYPES } from "Components/Forms/Messages";
import { Checkbox, TextInput } from "Components/Forms/Fields";
import Button from "Components/Forms/Button";
import { ERROR_MESSAGES } from "Helpers/messages";

function MarketingPreferences() {
  const { profile, setProfile } = userState();
  const { userType } = profile;
  const [errors, setErrors] = useState([]);
  const [successMessages, setSuccessMessages] = useState([]);
  const recaptchaRef = useRef(null);
  const endpoint =
    userType === "Public"
      ? "identity/users/my-profile-full"
      : "identity/users/my-profile-basic";
  let validationSchema;

  if (userType === "Public") {
    validationSchema = Yup.object({
      forename: Yup.string(),
      surname: Yup.string(),
      companyName: Yup.string(),
      phoneNumber: Yup.string(),
      jobTitle: Yup.string().required("Required"),
    });
  } else {
    validationSchema = Yup.object({
      surname: Yup.string().required("Required"),
    });
  }

  const onSubmit = async (values) => {
    let token;
    let payload;

    if (recaptchaRef?.current) {
      recaptchaRef.current.reset();
      token = await recaptchaRef.current.executeAsync();
    }

    setErrors([]);
    setSuccessMessages([]);

    if (userType === "Public") {
      payload = {
        forename: values.forename,
        surname: values.surname,
        companyName: values.companyName,
        phoneNumber: values.phoneNumber,
        jobTitle: values.jobTitle,
        acceptedFutureCommunications: values.acceptedFutureCommunications,
      };
    } else {
      payload = {
        forename: values.forename,
        surname: values.surname,
        acceptedFutureCommunications: values.acceptedFutureCommunications,
      };
    }

    axiosConfig
      .put(`${endpoint}`, payload, {
        headers: {
          "g-recaptcha-response": token,
        },
      })
      .then((res) => {
        let statusCode = res?.data?.statusCode;
        switch (statusCode) {
          case 200:
            const {
              data: { returnedObject },
            } = res;
            if (returnedObject) {
              setProfile(returnedObject);
            }
            setSuccessMessages([res?.data?.successMessage]);
            break;
          default:
            const errorMessages =
              res?.data?.errorMessages ||
              res?.response?.statusText ||
              `${ERROR_MESSAGES.GENERIC_ERROR}`;
            setErrors([errorMessages]);
        }
      });
  };

  return (
    <>
      <h2>Marketing preferences</h2>
      <Messages messages={errors} type={MESSAGE_TYPES.ERROR} />
      <Messages messages={successMessages} type={MESSAGE_TYPES.SUCCESS} />

      <Formik
        initialValues={{
          forename: profile.forename || "",
          surname: profile.surname || "",
          companyName: profile.companyName || "",
          phoneNumber: profile.phoneNumber || "",
          jobTitle: profile.jobTitle || "",
          email: profile.email || "",
          acceptedFutureCommunications:
            profile.acceptedFutureCommunications || false,
        }}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        enableReinitialize
      >
        {({ handleBlur, handleChange, values }) => (
          <>
            <Form>
              <TextInput
                label="First name"
                placeholder="First name"
                name="forename"
                onChange={handleChange}
                onBlur={handleBlur}
                readOnly={!!profile.forename}
              />
              <TextInput
                label="Last name"
                placeholder="Last name"
                name="surname"
                onChange={handleChange}
                onBlur={handleBlur}
                readOnly={!!profile.surname}
              />
              <TextInput
                label="Email address"
                placeholder="Email address"
                name="email"
                onChange={handleChange}
                onBlur={handleBlur}
                readOnly={!!profile.email}
              />
              <Checkbox
                id="acceptedFutureCommunications"
                name="acceptedFutureCommunications"
                value={values.acceptedFutureCommunications}
                checked={values.acceptedFutureCommunications}
                onChange={handleChange}
              >
                By checking this box, you agree to receive marketing
                communications about Visa business products and services. You
                can unsubscribe at any time. For more information on how Visa
                collects, uses, and protects your personal information, please
                review our{" "}
                <a
                  href="https://usa.visa.com/legal/privacy-policy.html"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Global Privacy Notice
                </a>
              </Checkbox>
              <div>
                <Button blue withIcon>
                  Update my preferences
                </Button>
              </div>
            </Form>
            <ReCAPTCHA
              ref={recaptchaRef}
              size="invisible"
              sitekey={CONFIG.RECAPTCHA_SITE_KEY}
            />
          </>
        )}
      </Formik>
    </>
  );
}

export default MarketingPreferences;
