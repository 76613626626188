import style from "./Priorities.module.scss";

const Priorities = () => {
  return (
    <div className={style.prioritiesWrapper}>

      <div>

        <div className={style.priorityItem}>
          <div className={style.number}>1</div>
          <div className={style.textBox}>
            <p>Health</p>
          </div>
        </div>
        <div className={style.priorityItem}>
          <div className={style.number}>2</div>
          <div className={style.textBox}>
            <p>Having enough money</p>
          </div>
        </div>
        <div className={style.priorityItem}>
          <div className={style.number}>3</div>
          <div className={style.textBox}>
            <p>Self-care and wellbeing</p>
          </div>
        </div>
        <div className={style.priorityItem}>
          <div className={style.number}>4</div>
          <div className={style.textBox}>
            <p>Spending time with family/friends</p>
          </div>
        </div>
        <div className={style.priorityItem}>
          <div className={style.number}>5</div>
          <div className={style.textBox}>
            <p>Maintaining work-life balance/starting/progressing my professional career</p>
          </div>
        </div>

      </div>

    </div>
  )
}

export default Priorities