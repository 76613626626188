import React from "react";
import { useNavigate } from "react-router";

import { ReactComponent as IconThankyou } from "Images/Vectors/thank-you-icon.svg";
import pageStyles from "Styles/page.module.scss";
import typographyStyles from "Styles/typography.module.scss";
import Button from "Components/Forms/Button";
import styles from "./ThankYou.module.scss";

function ThankYou() {
  const navigate = useNavigate();
  return (
    <div className={pageStyles.page}>
      <div className={styles.iconWrapper}>
        <IconThankyou />
      </div>
      <h1 className={typographyStyles.textCenter}>
        Thank you!
      </h1>
      <p className={typographyStyles.textCenter}>
        Your request has been submitted. The Visa team will be in contact with
        you as soon as possible.
      </p>
      <Button
        appearance="primaryBlue"
        withIcon
        onClick={() => navigate(-1)}
      >
        Back to previous page
      </Button>
    </div>
  );
}

export default ThankYou;
