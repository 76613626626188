import React from "react";
import styles from "./Messages.module.scss";
import ErrorIcon from "Images/Vectors/icon-error.svg";

export const MESSAGE_TYPES = {
  ERROR: "error",
  SUCCESS: "success",
};

function Messages({ messages, type }) {
  if (!Array.isArray(messages)) return null;

  return messages.length ? (
    <ul className={`${styles.messagesList} ${type}`}>
      {messages?.map((message, index) => (
        <li key={index}>
          {type === MESSAGE_TYPES.ERROR && <img src={ErrorIcon} alt="Error icon" />} {message}
        </li>
      ))}
    </ul>
  ) : (
    <></>
  );
}

export default Messages;
