import { Helmet } from "react-helmet";

import MainBanner from "../../Custom/MainBanner/MainBanner";
import ChapterTabs from "../../Layout/ChapterTabs/ChapterTabs";
import { chapters } from "../../Layout/ChapterTabs/ChapterTabsConfig";

const Home = () => {
  return (
    <div>
      <Helmet>
        <title>Gen Z and Finance: A New Paradigm</title>
      </Helmet>
      <MainBanner />
      <ChapterTabs chapters={chapters} />
    </div>
  )
}

export default Home