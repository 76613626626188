import CONFIG from "config";
import { Form, Formik } from "formik";
import { useRef, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import axiosConfig from "Services/axiosConfig";
import userState from "Stores/User";
import * as Yup from "yup";

import Button from "Components/Forms/Button";
import { TextInput } from "Components/Forms/Fields";

import { ROUTES } from "App";
import Messages, { MESSAGE_TYPES } from "Components/Forms/Messages";
import { API_SHORTCODES } from "Constants";
import { ERROR_MESSAGES } from "Helpers/messages";
import formStyles from "Styles/forms.module.scss";
import typographyStyles from "Styles/typography.module.scss";
import styles from "./LoginForm.module.scss";

function LoginForm({ onLogin = () => {} }) {
  const [errors, setErrors] = useState([]);
  const recaptchaRef = useRef(null);

  const { setProfile } = userState();

  const validationSchema = Yup.object({
    username: Yup.string().required("Required"),
    password: Yup.string().required("Required"),
  });

  const onSubmit = async (values) => {
    const token = await recaptchaRef.current.executeAsync();
    const headers = {
      "g-recaptcha-response": token,
    };
    recaptchaRef.current.reset();
    setErrors([]);

    try {
      await axiosConfig.post(`/identity/auth/token`, values, { headers });
    } catch (e) {
      let errors;
      const errorMessages = e?.response?.data?.errorMessages || [
        `${ERROR_MESSAGES.UNAUTHORIZED}`,
      ];

      if (errorMessages?.includes(API_SHORTCODES.ACCOUNT_LOCKED)) {
        errors = [
          <>
            Account locked. Please contact{" "}
            <a href="mailto:EUResearchandInsights@visa.com">
              EUResearchandInsights@visa.com
            </a>{" "}
            for further assistance.
          </>,
        ];
      } else if (errorMessages?.includes(API_SHORTCODES.INVALID_PASSWORD)) {
        errors = [<>Sorry, the credentials you have entered are incorrect.</>];
      } else if (errorMessages?.includes(API_SHORTCODES.USER_NOT_FOUND)) {
        errors = [<>Sorry, the credentials you have entered are incorrect.</>];
      } else if (errorMessages?.includes(API_SHORTCODES.EMAIL_UNCONFIRMED)) {
        errors = [
          <>
            You must confirm your email address before logging in. Please check
            your inbox and follow the instructions in the confirmation email.
          </>,
        ];
      } else if (errorMessages?.includes(API_SHORTCODES.USER_UNVERIFIED)) {
        errors = [
          <>
            Your account has not yet been verified. Please contact{" "}
            <a href="mailto:EUResearchandInsights@visa.com">
              EUResearchandInsights@visa.com
            </a>{" "}
            for further assistance.
          </>,
        ];
      } else if (errorMessages?.includes(API_SHORTCODES.USER_UNVERIFIED)) {
        errors = [
          <>
            Your account has not yet been verified. Please contact{" "}
            <a href="mailto:EUResearchandInsights@visa.com">
              EUResearchandInsights@visa.com
            </a>{" "}
            for further assistance.
          </>,
        ];
      } else {
        errors = errorMessages;
      }
      setErrors(errors);
      return;
    }

    try {
      const { data } = await axiosConfig.get(`/my-user-info`);
      const { returnedObject } = data;
      const { profile } = returnedObject;
      if (profile) {
        setProfile(profile);
        onLogin();
      }
    } catch (e) {
      const errorMessages = e?.data?.errorMessages;
      setErrors(errorMessages);
    }
  };

  return (
    <>
      <Helmet>
        <title>Login</title>
      </Helmet>

      <ReCAPTCHA
        ref={recaptchaRef}
        size="invisible"
        sitekey={CONFIG.RECAPTCHA_SITE_KEY}
      />

      <Formik
        initialValues={{ username: "", password: "" }}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({ values, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
          <>
            <Form className={styles.loginForm}>
              <Messages messages={errors} type={MESSAGE_TYPES.ERROR} />
              <div className={formStyles.formRow}>
                <h1 className={typographyStyles.textCenter}>
                  Welcome to VISA Gen Z Insights
                </h1>
              </div>
              <div className={formStyles.formRow}>
                <p className={typographyStyles.textCenter}>
                  Log in to learn more about Gen Z and their attitudes and
                  behaviours towards money.
                </p>
                <p className={typographyStyles.textCenter}>
                  New user?{" "}
                  <Link to={ROUTES.CREATE_ACCOUNT}>Create an account here</Link>
                </p>
              </div>
              <div className={formStyles.formRow}>
                <TextInput
                  type="text"
                  value={values.username}
                  label="Username"
                  placeholder="Email"
                  name="username"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  maxLength="255"
                  required
                />
              </div>
              <div className={formStyles.formRow}>
                <TextInput
                  type="password"
                  value={values.password}
                  label="Password"
                  placeholder="Password"
                  name="password"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  maxLength="255"
                  required
                />
              </div>
              <div className={formStyles.formRow}>
                <Button loading={isSubmitting} type="submit">
                  Sign In
                </Button>
              </div>
            </Form>
            <div className={formStyles.formRow}>
              <div className={styles.forgotPassword}>
                <p className={typographyStyles.textCenter}>
                  Forgotten your password?
                  <br />
                  <Link to={ROUTES.REQUEST_PASSWORD_RESET}>
                    Reset your password here
                  </Link>
                </p>
                <p className={typographyStyles.textCenter}>
                  <small>
                    If you need help accessing the site, please email{" "}
                    <a href="mailto:EUResearchandInsights@visa.com">
                      EUResearchandInsights@visa.com
                    </a>
                  </small>
                </p>
              </div>
            </div>
            <ReCAPTCHA
              ref={recaptchaRef}
              size="invisible"
              sitekey={CONFIG.RECAPTCHA_SITE_KEY}
            />
          </>
        )}
      </Formik>
    </>
  );
}
export default LoginForm;
