import style from "./ScrollIndicator.module.scss";
import typ from "../../../Styles/typography.module.scss";
import cn from "classnames";

import scrollIconBlue from "./assets/scroll-prompt-blue.svg";
import scrollIconGold from "./assets/scroll-prompt-gold.svg";

const ScrollIndicator = ({white}) => {
  return (
    <div className={cn(style.scrollIndicator, { [style.white] : white })}>
        {white ? (
            <img src={scrollIconGold} alt="" />
        ) : (
            <img src={scrollIconBlue} alt="" />
        )}
        <p className={typ.fontMedium}>Scroll to explore</p>
    </div>
  )
}

export default ScrollIndicator