import { Helmet } from "react-helmet";

import layout from "../../../../Styles/layout.module.scss";
import typ from "../../../../Styles/typography.module.scss";

import ThumbsUp from "Images/Vectors/brandicon-thankyou.svg";

const EmailConfirmationMessage = () => {
  return (
    <section>

        <Helmet>
          <title>Nearly there!</title>
        </Helmet>

        <div className="inner">

            <div className={layout.functionalPage}>

                <img src={ThumbsUp} alt="" />
                <h1>Nearly there!</h1>
                <p className={typ.fontSemiBold}>We have just sent you a password reset email, which is only valid for <span>24 hours</span>.</p>
                <p>Please check your junk mail folder if this is not in your inbox, or contact <a href="#">EUResearchandInsights@visa.com</a> for assistance.</p>
                
            </div>

        </div>
    </section>
  )
}

export default EmailConfirmationMessage