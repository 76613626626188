import { ROUTES } from "App";
import Button from "Components/Forms/Button";
import { TextInput } from "Components/Forms/Fields";
import Messages, { MESSAGE_TYPES } from "Components/Forms/Messages";
import CONFIG from "config";
import { Formik } from "formik";
import { ERROR_MESSAGES } from "Helpers/messages";
import { useRef, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { useNavigate } from "react-router-dom";
import axiosConfig from "Services/axiosConfig";
import formStyles from "Styles/forms.module.scss";
import * as Yup from "yup";

const CreatePasswordForm = () => {
    const [errors, setErrors] = useState([]);
    const recaptchaRef = useRef(null);

    const navigate = useNavigate();

    const validationSchema = Yup.object({
        email: Yup.string().email("Invalid email address").required("Required"),
    });

    const onSubmit = async (values) => {
        const token = await recaptchaRef.current.executeAsync();
        recaptchaRef.current.reset();
        setErrors([]);

        axiosConfig
          .post(
            `/identity/users/forgotten-password`,
            {
              email: values.email,
            },
            {
              headers: {
                "g-recaptcha-response": token,
              },
            }
          )
          .then((res) => {
            let statusCode = res?.data?.statusCode;
            switch (statusCode) {
              case 200:
                navigate(ROUTES.REQUEST_PASSWORD_RESET_CONFIRMATION)
                break;
              default:
                const errorMessages =
                  res?.data?.errorMessages ||
                  res?.response?.statusText ||
                  `${ERROR_MESSAGES.GENERIC_ERROR}`;
                setErrors([errorMessages]);
            }
          });
      };

    return (
        <div>
            <Formik
                enableReinitialize
                initialValues={{}}
                validationSchema={validationSchema}
                onSubmit={onSubmit} >
                {({ handleBlur, handleChange, handleSubmit, values, isSubmitting }) => (
                <>
                    <form onSubmit={handleSubmit}>
                        <Messages messages={errors} type={MESSAGE_TYPES.ERROR} />
                        <div className={formStyles.formRow}>
                          <TextInput
                              type="email"
                              label="email"
                              placeholder="Email address"
                              name="email"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              required
                          />
                        </div>
                        <div>
                            <Button loading={isSubmitting} type="submit" appearance="primaryBlue" withIcon>
                                Reset password  
                            </Button>
                        </div>
                    </form>
                    <ReCAPTCHA
                    ref={recaptchaRef}
                    size="invisible"
                    sitekey={CONFIG.RECAPTCHA_SITE_KEY}
                    />
                </>
                )}
            </Formik>

        </div>
    )
}

export default CreatePasswordForm