import { useRef, useEffect } from "react";
import style from "./ChapterTabs.module.scss";
import typ from "../../../Styles/typography.module.scss";
import { Link, useLocation } from "react-router-dom";
import cn from "classnames";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

const ChapterTabs = ({chapters}) => {

    gsap.registerPlugin(ScrollTrigger);

    const chapterTabsRef = useRef();

    var preAnimate = false;

    if(useLocation().pathname === "/") {
        preAnimate = true;
    }

    useEffect(() => {

        if(preAnimate) {

            var cttl = gsap.timeline({
                scrollTrigger: {
                  trigger: ".chapterTabsWrapper",
                  start: "top 50%",
                  end: "+=1000",
                  scrub: false
                } 
              });
          
              cttl.to(".chapterTabsWrapper > div:nth-child(2)", { transform: "translateY(0)", duration: 1, ease: "power4.out", }, "-=.8");
              cttl.to(".chapterTabsWrapper > div:nth-child(3)", { transform: "translateY(0)", duration: 1, ease: "power4.out", }, "-=.8");
              cttl.to(".chapterTabsWrapper > div:nth-child(4)", { transform: "translateY(0)", duration: 1, ease: "power4.out", }, "-=.8");
              cttl.to(".chapterTabsWrapper > div:nth-child(5)", { transform: "translateY(0)", duration: 1, ease: "power4.out", }, "-=.8");
              cttl.to(".chapterTabsWrapper > div:nth-child(6)", { transform: "translateY(0)", duration: 1, ease: "power4.out", }, "-=.8");

        }

        return () => {

            if(preAnimate) {

                cttl.scrollTrigger.kill();
                cttl.kill();

            }
        };
    }, []);

    return (
        <section ref={chapterTabsRef} className={cn(style.chapterTabs, { [style.preAnimate] : preAnimate }, "chapterTabsWrapper")}>
            {Object.values(chapters).map((chapter, i) => (
                
                <div className={cn(style.chapterTabsItem, {[style.active] : chapter.active})} key={i}>
                    <Link to={chapter.url}>
                        <img src={chapter.image} alt="" />
                        <div className={style.chapterTabsText}>
                            <p className={cn(typ.colorWhite, style.title)}>{chapter.title}</p>
                            <p className={typ.bodyCopy2}>
                                {chapter.linkText}
                            </p>
                        </div>
                    </Link>
                </div>
            ))}
        </section>
    )
}

export default ChapterTabs