import cn from 'classnames';
import Spinner from "Components/Spinner";
import { Link } from "react-router-dom";
import style from "./Button.module.scss";

const Button = ({gold, children, type = 'submit', to, loading = false }) => {

  return (
    <>
      {to ? (
        <Link className={cn(style.button, gold ? style.gold : style.blue)} to={to}>
          {loading ? (
          <span className={style.spinnerWrapper}>
            <Spinner />
          </span>
        ) : null}
          {children}
        </Link>
      ) : (
        <button type={type} className={cn(style.button, gold ? style.gold : style.blue)}>
          {loading ? (
          <span className={style.spinnerWrapper}>
            <Spinner />
          </span>
        ) : null}
          {children}
        </button>
      )}
    </>
    
  )
}

export default Button