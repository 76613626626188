import { useRef, useEffect } from "react";
import style from "./CoasterImageContent.module.scss";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

const CoasterImageContent = ({img1, img2, children, reverse}) => {

    gsap.registerPlugin(ScrollTrigger);

    const coasterSectionRef = useRef();
    const coasterSlideRef = useRef();

    useEffect(() => {
        var tlc = gsap.timeline({
          scrollTrigger: {
            trigger: coasterSectionRef.current,
            start: "top 20%",
            end: "+=200",
            scrub: 1
          } 
        });
    
        tlc.to(coasterSlideRef.current, { transform: "translateY(-100px)", duration: 1, }, "-=.5");

        return () => {
            tlc.scrollTrigger.kill();
            tlc.kill();
        };
      }, []);

    return (
        <section ref={coasterSectionRef} className={reverse && style.reverse}>
            <div className="inner">

                <div className={style.coasterContentWrapper}>
                    <div className={style.left}>
                        <img src={img1} alt="" />
                        <img src={img2} alt="" ref={coasterSlideRef} />
                    </div>

                    <div className={style.right}>
                        {children}
                    </div>
                </div>

            </div>
        </section>
    )
}

export default CoasterImageContent