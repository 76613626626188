import { sanitizeUrl } from "@braintree/sanitize-url";
import { ROUTES } from "App";
import Button from "Components/Forms/Button";
import { TextInput } from "Components/Forms/Fields";
import Messages, { MESSAGE_TYPES } from "Components/Forms/Messages";
import CONFIG from "config";
import { API_SHORTCODES } from "Constants";
import UserContext from "Context/UserContext";
import { Form, Formik } from "formik";
import { Login } from "Helpers/login";
import { ERROR_MESSAGES } from "Helpers/messages";
import { useContext, useRef, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { useNavigate } from "react-router-dom";
import axiosConfig from "Services/axiosConfig";
import userState from "Stores/User";
import * as Yup from "yup";

const ResetPasswordForm = () => {
  const [errors, setErrors] = useState([]);
  const [isLoggedIn, setIsLoggedIn] = useContext(UserContext);
  const { setProfile } = userState();
  const recaptchaRef = useRef(null);
  const navigate = useNavigate();

  const validationSchema = Yup.object({
    password: Yup.string().required("Required"),
    confirmPassword: Yup.string().oneOf([Yup.ref("password"), null], "Passwords must match"),
  });

  const sanitisedUrl = sanitizeUrl(window.location.href);
  const url = new URL(sanitisedUrl);
  const code = url.searchParams.get("code");
  const email = url.searchParams.get("email");

  const onSubmit = async ({ password, confirmPassword }) => {
    let token = await recaptchaRef.current.executeAsync();
    recaptchaRef.current.reset();
    setErrors([]);
    const payload = {
      code: code,
      password,
      email,
      confirmPassword: confirmPassword,
    };
    const config = {
      headers: {
        "g-recaptcha-response": token,
      },
    };

    try {
      await axiosConfig.post(`/identity/users/reset-password`, payload, config);
      const authToken = await recaptchaRef.current.executeAsync();
      const loginRes = await Login(email, password, authToken);
      const { returnedObject } = loginRes;
      if (returnedObject) {
        setProfile(returnedObject);
        navigate(ROUTES.HOME, { replace: true });
      }
    } catch (error) {
      const errorMessages =
        error.response?.data?.errorMessages ||
        error.response?.response?.statusText ||
        `${ERROR_MESSAGES.GENERIC_ERROR}`;
      if (errorMessages) {
        let errors;
        if (errorMessages?.includes(API_SHORTCODES.SAME_PASSWORD)) {
          errors = [
            "Your password is the same as a previously used version. Please enter an alternative password. For assistance, please contact EUResearchandInsights@visa.com.",
          ];
        } else if (errorMessages?.includes(API_SHORTCODES.INVALID_TOKEN)) {
          navigate(ROUTES.PASSWORD_RESET_EXPIRED + "/?email=" + email);
        } else {
          errors = errorMessages;
        }
        setErrors(errors);
      } else {
        setErrors(["Sorry, there was an unexpected problem."]);
      }
    }
  };

  return (
    <div>
      <Formik
        enableReinitialize
        initialValues={{
          password: "",
        }}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({ handleBlur, handleChange, isSubmitting }) => (
          <>
            <Form>
              <Messages messages={errors} type={MESSAGE_TYPES.ERROR} />
              <TextInput
                type="password"
                label="password"
                placeholder="Password"
                name="password"
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <TextInput
                type="password"
                label="confirmPassword"
                placeholder="Confirm password"
                name="confirmPassword"
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <div>
                <Button loading={isSubmitting} type="submit" appearance="primaryBlue" withIcon>
                  Confirm and sign in
                </Button>
              </div>
            </Form>
            <ReCAPTCHA ref={recaptchaRef} size="invisible" sitekey={CONFIG.RECAPTCHA_SITE_KEY} />
          </>
        )}
      </Formik>
    </div>
  );
};

export default ResetPasswordForm;
