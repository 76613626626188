import { useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import layout from "../../../Styles/layout.module.scss";
import typ from "../../../Styles/typography.module.scss";
import style from "./Chapter1.module.scss";

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

import ChapterBanner from "../../Layout/ChapterBanner/ChapterBanner";
import Chapter1Person from "./assets/chapter-banner-1.png";

import Quote from "../../Layout/Quote/Quote";

import RankingBar from "../../Custom/RankingBar/RankingBar";

import CoasterImageContent from "../../Layout/CoasterImageContent/CoasterImageContent";
import PersonProtest from "./assets/person-at-protest.jpg";
import PersonPhone from "./assets/person-on-phone.jpg";

import BackgroundGradient from "../../UI/BackgroundGradient/BackgroundGradient";

import Video from "Components/UI/Video/Video";
import Video1 from "Videos/Ch1-UK21-bankrupt.mp4";
import Video2 from "Videos/Ch1-UK24-insecurity.mp4";
import Video3 from "Videos/Ch1-DE24-pension.mp4";

import PercentGraph from "../../UI/PercentGraph/PercentGraph";

import ChapterTabs from "../../Layout/ChapterTabs/ChapterTabs";
import { chapters } from "../../Layout/ChapterTabs/ChapterTabsConfig";

const Chapter1 = () => {

    gsap.registerPlugin(ScrollTrigger);

    const [chapterState, setChapterState] = useState(chapters);

    useEffect(() => {
        setChapterState(prevState => ({
        ...prevState,
        one: {
            ...prevState.one,
            active: true
        }
        }));
    }, []);

    const videoSectionRef = useRef();
    const videoOneRef = useRef();
    const videoTwoRef = useRef();
    const videoWrapperRef = useRef();

    useEffect(() => {
        var c1vs = gsap.timeline({
            scrollTrigger: {
            trigger: videoSectionRef.current,
            start: "top 70%",
            end: "+=500",
            scrub: 1
            } 
        });

        c1vs.to(videoWrapperRef.current, { transform: "translateY(0px)", duration: 1, }, "0");
        c1vs.to(videoOneRef.current, { transform: "translateY(0px)", duration: 1, }, "0");
        c1vs.to(videoTwoRef.current, { transform: "translateY(0px)", duration: 1, }, "0");

        return () => {
            c1vs.scrollTrigger.kill();
            c1vs.kill();
        };
    }, []);

    useEffect(() => {
        setTimeout(() => {
            ScrollTrigger.refresh();
        }, 200)
    }, []);

    return (
        <div>

            <Helmet>
                <title>Gen Z: The product of their time</title>
            </Helmet>

            <BackgroundGradient colorFrom="#1434CB" colorTo="#FFFFFF" gradientStart="top top" gradientDuration="1500">
                
                <ChapterBanner
                    title="Gen Z: The product of their time"
                    copy="Gen Z are more comfortable discussing finance over topics like mental health, sex, religion, politics and death, and leverage this knowledge to help them prepare for their future."
                    image={Chapter1Person}
                    color="white"
                />

                <Quote 
                quote="I feel absolutely comfortable talking about money with my family and the same applies to my friends. This is not a topic that we frequently discuss among friends, but when it happens, I feel absolutely comfortable and sometimes I find it very interesting to hear what my peers think."
                source="IT, 21"
                color="blue"
                />

            </BackgroundGradient>

            <RankingBar />
            
            <CoasterImageContent img1={PersonPhone} img2={PersonProtest}>
                <h4 className={typ.fontRegular}>
                    Gen Z witnessed problems of job security and financial instability amongst older generations.
                </h4>
                <p>
                    They’ve grown up as digital natives, not knowing life before smartphones and the internet where information is at their fingertips.
                </p>
                <p>
                    They’ve had to adapt fast, and the emergence of self-starters is a signal of their drive to maximise their capital at an early age.
                </p>
            </CoasterImageContent>

            <Quote 
                quote="My parents got separated when I was eight years old, so since then, money has sometimes been an issue and it’s always being discussed."
                source="IT, 23"
                color="blue"
            /> 
            
            <BackgroundGradient colorFrom="#FFFFFF" colorTo="#FCC015"  gradientStart="top 50%" gradientDuration="300">

                <CoasterImageContent img1={PersonPhone} img2={PersonProtest} reverse>
                    <h4 className={typ.fontRegular}>
                        'Coming of age' during economically tumultuous times has inherently shaped the way Gen Z think about their financial priorities.
                    </h4>
                    <p>
                        They have watched their parents’ generation struggle through finances and are hyperaware of the messages the media are conveying about retirement, home ownership etc.
                    </p>
                    <p>
                        This feeling of insecurity has been exacerbated by their experiences during COVID-19 in which the global economies and personal finances have been put to the test.​
                    </p>
                </CoasterImageContent>

                <section ref={videoSectionRef}>
                    <div className="inner">
                        
                        <div className={style.threeCol} ref={videoWrapperRef}>

                            <div className={style.left} ref={videoOneRef}>
                                <Video src={Video1} />
                            </div>
                            
                            <div className={style.mid}>
                                <Video src={Video2} />
                            </div>

                            <div className={style.right} ref={videoTwoRef}>
                                <Video src={Video3} />
                            </div>

                        </div>

                    </div>
                </section>
                
                <section>
                    <div className="inner">

                        <div className={layout.twoCol}>

                            <PercentGraph percent="77">
                                <h4>say it’s important to become financially independent.</h4>
                                <p>NET very/extremely important.</p>
                            </PercentGraph>

                            <PercentGraph percent="70">
                                <h4>say saving for the future is always on their mind.</h4>
                                <p>NET somewhat/completely agree.</p>
                            </PercentGraph>

                        </div>

                    </div>
                </section>

            </BackgroundGradient>

            <ChapterTabs chapters={chapterState} />
        </div>
        
    )
}

export default Chapter1