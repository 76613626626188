import { createContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { isAuthenticated } from "Services/auth";

const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(isAuthenticated());
  const { pathname } = useLocation();

  useEffect(() => {
    const checkLoggedIn = () => {
      let cuser = isAuthenticated();
      setCurrentUser(cuser);
    };

    checkLoggedIn();
  }, [pathname]);

  return (
    <UserContext.Provider value={[currentUser, setCurrentUser]}>
      {children}
    </UserContext.Provider>
  );
};

export default UserContext;
