export const organisationTypeOptions = [
  {
    value: "Acquirer",
    label: "Acquirer",
  },
  {
    value: "Payment Gateway / Payment Facilitator",
    label: "Payment Gateway / Payment Facilitator",
  },
  {
    value: "ISV / ISO",
    label: "ISV / ISO",
  },
  {
    value: "Merchant",
    label: "Merchant",
  },
  {
    value: "Fintech",
    label: "Fintech",
  },
  {
    value: "Issuer",
    label: "Issuer",
  },
  {
    value: "government",
    label: "Government",
  },
  {
    value: "Other",
    label: "Other",
  },
];

export const organisationSizeOptions = [
  {
    value: "1 - 10 employees",
    label: "1 - 10 employees",
  },
  {
    value: "11 - 50 employees",
    label: "11 - 50 employees",
  },
  {
    value: "51 - 100 employees",
    label: "51 - 100 employees",
  },
  {
    value: "101 - 250 employees",
    label: "101 - 250 employees",
  },
  {
    value: "251 - 500 employees",
    label: "251 - 500 employees",
  },
  {
    value: "500+ employees",
    label: "500+ employees",
  },
];
